import { StoItem } from '@components/Deploy/types';
import { createContext, useContext, useState, ReactElement, ReactNode } from 'react';

interface IStoContextProvider {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[];
}

interface IStoContext {
  stoItem: StoItem | null;
  setStoItem?: React.Dispatch<React.SetStateAction<StoItem | null>>;
  handleValueChange?: (value: string | File, name: string) => void;
  handleSelectChange?: (title: string, value: string) => void;
  landFile?: File | null;
  isReviewRefused: boolean;
  isReviewApproved: boolean;
  isBeingReviewed: boolean;
  isReviewCancel: boolean;
  isRealEstate: boolean;
}

const StoContext = createContext<IStoContext>({
  stoItem: {
    accountOrg: '',
    blockchainId: '',
    stockType: '',
    tokenName: '',
    symbol: '',
    volume: 0,
    propertyName: '',
    propertyTypeName: '',
    area: '',
    size: '',
    owner: '',
    email: '',
    likeCount: '',
    viewCount: '',
    tokenImageUrl: '',
    stoTrType: '',
    title: '',
    landFileName: '',
    landFile: '',
    channelLink: '',
    channelName: '',
    status: '',
  },
  isReviewRefused: false,
  isReviewApproved: false,
  isBeingReviewed: false,
  isReviewCancel: false,
  isRealEstate: false,
});

export function StoContextProvider({ children }: IStoContextProvider) {
  const [stoItem, setStoItem] = useState<StoItem | null>(null);
  const [landFile, setLandFile] = useState<File | null>(null);

  const handleValueChange = (value: string | File, name: string) => {
    if (name === 'landFile') {
      handleSelectChange('landFileName', (value as File).name);
      return setLandFile(value as File);
    }
    const newItem = {
      ...stoItem,
      [name]: value,
    };
    setStoItem(newItem as StoItem);
  };

  const handleSelectChange = (title: string, value: string) => {
    if (!value && !title) {
      return;
    }
    const { codeId, codeName } = JSON.parse(value);

    if (title === 'property') {
      const newItem = {
        ...stoItem,
        [title]: codeId,
        propertyName: codeName,
        propertyTypeName: '',
        propertyType: '',
      };
      return setStoItem(newItem as StoItem);
    }
    if (title === 'propertyType') {
      const newItem = {
        ...stoItem,
        [title]: codeId,
        propertyTypeName: codeName,
      };
      return setStoItem(newItem as StoItem);
    }
    const newItem = {
      ...stoItem,
      [title]: codeId,
    };
    setStoItem(newItem as StoItem);
  };

  const isReviewRefused = stoItem?.status === 'REFUSE';
  const isReviewApproved = stoItem?.status === 'APPROVAL';
  const isReviewCancel = stoItem?.status === 'CANCEL';
  const isBeingReviewed = stoItem?.status === 'REVIEW';
  const isRealEstate = stoItem?.stoTrType === 'REALESTATE';

  return (
    <StoContext.Provider
      value={{
        stoItem,
        setStoItem,
        handleValueChange,
        handleSelectChange,
        landFile,
        isReviewRefused,
        isReviewApproved,
        isBeingReviewed,
        isReviewCancel,
        isRealEstate,
      }}
    >
      {children}
    </StoContext.Provider>
  );
}

export function useStoContext() {
  return useContext(StoContext);
}
