import { Button, ConfirmModal, Icon, Loading } from '@components/common';
import { postStoRequestType, FormType, RealEstateRequestType, YoutubeRequestType } from './types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAuthContext } from '@context/AuthContext';
import { useToastContext } from '@context/ToastContext';
import DeployToastContent from './DeployToastContent';
import { postSto } from '@apis/sto';

interface IDeployButtonForm {
  form: FormType;
  setIsApply: Dispatch<SetStateAction<boolean>>;
  platform: string;
}

const DeployButtonForm = ({ form, setIsApply, platform }: IDeployButtonForm) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deployType, setDeployType] = useState('');
  const { user } = useAuthContext();
  const { setContents, setTime } = useToastContext();
  const modalData = {
    isOn: confirmModal,
    title: '토큰증권(ST) 발행을 신청하시겠습니까?',
    contents:
      '신청이 완료되면 영업일 기준 7일 이내 심사가 진행되며, 이후 블록체인에 기록되어 토큰 정보를 수정하실 수 없습니다.',
  };

  useEffect(() => {
    if (platform === '유튜브') {
      setDeployType('youtube');
    } else if (platform === '부동산') {
      setDeployType('realestate');
    }
  }, [platform]);

  const confirmModalHandler = () => {
    const { tokenInfo, youtubePlatform, realEstatePlatform } = form;
    if (!user) {
      setContents?.(<DeployToastContent toastContent="로그인이 필요합니다." />);
      setTime?.(4000);
    } else {
      setIsApply(true);
      if (
        !tokenInfo['발행 증권사'] ||
        !tokenInfo['블록체인'] ||
        !tokenInfo['심볼'] ||
        !tokenInfo['심볼 이미지'] ||
        !tokenInfo['증권 유형'] ||
        !tokenInfo['토큰 발행량'] ||
        !tokenInfo['토큰명']
      ) {
        return;
      }
      if (platform === '유튜브') {
        if (
          !youtubePlatform['구독자 수'] ||
          !youtubePlatform['이메일 주소'] ||
          !youtubePlatform['주제'] ||
          !youtubePlatform['채널 링크'] ||
          !youtubePlatform['채널명' || !youtubePlatform['총 조회수']]
        ) {
          return;
        }
      } else if (platform === '부동산') {
        if (
          !realEstatePlatform['건축물대장'] ||
          !realEstatePlatform['공급/전용 면적'] ||
          !realEstatePlatform['소유자명'] ||
          !realEstatePlatform['소재지'] ||
          !realEstatePlatform['용도'] ||
          !realEstatePlatform['이메일 주소'] ||
          !realEstatePlatform['종류']
        ) {
          return;
        }
      }
      setConfirmModal(true);
    }
  };

  const formSaveButtonClick = () => {
    sessionStorage.setItem('formSave', JSON.stringify(form));
    setContents?.(<DeployToastContent toastContent="임시저장을 성공하였습니다." />);
    setTime?.(4000);
  };

  const deployButtonClick = async () => {
    try {
      setLoading(true);
      const { realEstatePlatform, tokenInfo, youtubePlatform } = form;
      const requestFunc = () => {
        if (deployType === 'youtube') {
          return {
            accountOrg: JSON.parse(tokenInfo['발행 증권사']).codeId,
            blockchainId: JSON.parse(tokenInfo['블록체인']).codeId,
            stockType: tokenInfo['증권 유형'],
            tokenName: tokenInfo.토큰명,
            symbol: tokenInfo.심볼,
            volume: String(tokenInfo['토큰 발행량']).replaceAll(/,/g, ''),
            channelLink: youtubePlatform['채널 링크'],
            channelName: youtubePlatform['채널명'],
            likeCount: String(youtubePlatform['구독자 수']).replaceAll(/,/g, ''),
            viewCount: String(youtubePlatform['총 조회수']).replaceAll(/,/g, ''),
            title: youtubePlatform['주제'],
            email: youtubePlatform['이메일 주소'],
          };
        } else if (deployType === 'realestate') {
          return {
            accountOrg: JSON.parse(tokenInfo['발행 증권사']).codeId,
            blockchainId: JSON.parse(tokenInfo['블록체인']).codeId,
            stockType: tokenInfo['증권 유형'],
            tokenName: tokenInfo.토큰명,
            symbol: tokenInfo.심볼,
            volume: String(tokenInfo['토큰 발행량']).replaceAll(/,/g, ''),
            property: JSON.parse(realEstatePlatform['종류']).codeId,
            propertyType: JSON.parse(realEstatePlatform['용도']).codeId,
            area: realEstatePlatform.소재지,
            size: realEstatePlatform['공급/전용 면적'],
            owner: realEstatePlatform.소유자명,
            email: realEstatePlatform['이메일 주소'],
          };
        }
        return null;
      };
      const request = requestFunc() as RealEstateRequestType | YoutubeRequestType;

      const requestData: postStoRequestType = {
        landFile: realEstatePlatform.건축물대장,
        tokenImageFile: tokenInfo['심볼 이미지'],
        request,
      };
      const data = await postSto(deployType, requestData);
      if (data.success) {
        sessionStorage.removeItem('sidebarMenu');
        sessionStorage.removeItem('formSave');
        setContents?.(<DeployToastContent toastContent={'토큰 발행 신청을 성공했습니다!'} />);
        setTime?.(4000);
      } else {
        setContents?.(<DeployToastContent toastContent={data} />);
        setTime?.(4000);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response) {
        setContents?.(<DeployToastContent toastContent={e.response.data.msg} />);
        setTime?.(4000);
      }
    } finally {
      setConfirmModal(false);
      setLoading(false);
    }
  };

  return (
    <div className="deploy_button_form">
      {confirmModal ? (
        <ConfirmModal modalData={modalData} onClickClose={() => setConfirmModal(false)}>
          <Button className="button cancel_button" color="white" onClick={() => setConfirmModal(false)}>
            취소
          </Button>
          <Button className="button apply_button" onClick={deployButtonClick}>
            신청하기
          </Button>
        </ConfirmModal>
      ) : null}
      <div className="fee_information">
        {loading ? <Loading /> : null}
        <div className="left">
          <Icon iconName="info" />
          <span>발행수수료 안내</span>
        </div>
        <Icon className="right" iconName="arrowRight_blue" />
      </div>
      <div className="button_form">
        <Button color="white" className="save_button" onClick={formSaveButtonClick}>
          임시저장
        </Button>
        <Button className="apply_button" onClick={confirmModalHandler}>
          <Icon iconName="apply" />
          <span>발행 신청하기</span>
        </Button>
      </div>
    </div>
  );
};

export default DeployButtonForm;
