import { Button } from '@components/common';
import Icon from '@components/common/Icon/Icon';
import { useState } from 'react';
import { FormToastContent } from '@components/MyPage';
import { useToastContext } from '@context/ToastContext';
import { useParams } from 'react-router';
import useToken from '@hooks/useToken';
import { commaSeparator } from '@utils/string';

interface IFormTokenAdd {
  setFormModalData: React.Dispatch<React.SetStateAction<string>>;
  tokenId: string;
  volume: number;
}
export default function FormTokenAdd({ setFormModalData, tokenId, volume }: IFormTokenAdd) {
  const [addAmount, setAddAmount] = useState('');
  const [comment, setComment] = useState('');
  const { setContents, setTime } = useToastContext();
  const { id: stoInfoId } = useParams();
  const { postTokenReview } = useToken({ tokenId: tokenId as string, historyType: 'mint' });

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (validation()) {
      postTokenReview.mutate(
        {
          requestData: {
            status: 'MINT',
            comment,
            stoInfoId,
            tokensId: tokenId,
            amount: parseInt(0 + addAmount),
          },
        },
        {
          onSuccess: () => {
            setContents?.(<FormToastContent type="추가발행" />);
            setTime?.(4000);
            setFormModalData('');
          },
        },
      );
    }
  };

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setFormModalData('');
  };

  const validation = () => {
    if (!comment) {
      setContents?.('정지 사유를 입력해주세요');
      return false;
    }
    if (!addAmount) {
      setContents?.('추가발행 토큰 수량을 입력해주세요');
      return false;
    }
    return true;
  };

  return (
    <form className="form-token-add">
      <button className="close-icon" onClick={(e) => handleCloseModal(e)}>
        <Icon iconName="close" />
      </button>
      <h1 className="modal-header">토큰 추가발행 신청하기 </h1>
      <p className="modal-pre-header">{`총 발행량 중 유통되지 않고 보유 중인 모든 토큰을 소각할 수 있으며, \n 이미 소각한 토큰은 되돌릴 수 없습니다.`}</p>
      <div className="add-amount__wrapper">
        <div className="header-wrapper">
          <span className="header">추가발행 토큰 수량</span>
          <div className="value-box">
            <span className="label">현재 발행량: </span>
            <span className="value">{commaSeparator(volume)}</span>
          </div>
        </div>
        <input
          type="number"
          className="add-amount__input"
          placeholder="추가로 발행할 토큰의 양을 숫자로만 입력해주세요."
          value={addAmount}
          onChange={(e) => setAddAmount(e.target.value)}
        />
      </div>
      <div className="reason-textarea">
        <h3 className="header">추가발행 사유</h3>
        <textarea
          className="reason-textarea__textarea"
          placeholder="추가발행 사유를 500자 이내로 입력해주세요."
          value={comment}
          maxLength={500}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
      </div>
      <div className="submit-wrapper">
        <Button variant="outline" onClick={(e) => handleCloseModal(e)} className="close-button">
          취소
        </Button>
        <Button
          className="submit-button"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          신청하기
        </Button>
      </div>
    </form>
  );
}
