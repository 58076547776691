import { Dispatch, SetStateAction } from 'react';

interface ITabProps {
  title?: string;
  tabElements: string[];
  length?: number;
  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;
}
const Tab = ({ title, tabElements, length, currentTab, setCurrentTab }: ITabProps) => {
  return (
    <div className="tab">
      <span className="title">{title}</span>
      <div className="tab_element_wrapper">
        {tabElements.map((tabElement) => (
          <span
            className={currentTab === tabElement ? `tab_element active` : `tab_element inactive`}
            onClick={() => setCurrentTab(tabElement)}
            key={tabElement}
          >
            {tabElement}
            {length && currentTab === tabElement ? <span className="length">{length}</span> : null}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Tab;
