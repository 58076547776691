import { Button, Icon } from '@components/common';
import { useNavigate } from 'react-router-dom';

const HomeContent = () => {
  const navigate = useNavigate();
  const routeToPublicOffering = () => {
    navigate('/public-offering');
  };

  return (
    <div className="home_content">
      <div className="main_button_wrapper">
        <span className="main_title">자산에 가치를 더하는</span>
        <div>
          <span className="main_title">토큰 증권 플랫폼 </span>
          &nbsp;
          <span className="main_title gradation">실버마인</span>
        </div>
        <div className="description">
          <span>✓ Asset Tokenization</span>
          <span>✓ High-Fluidity</span>
          <span>✓ All in One</span>
        </div>
        <Button className="main_button" onClick={routeToPublicOffering} endIcon={<Icon iconName="arrow_right_white" />}>
          See More
        </Button>
      </div>
    </div>
  );
};

export default HomeContent;
