import { useSearchParams } from 'react-router-dom';

function NotFound() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const message = searchParams.get('message');

  return (
    <main className="not-found">
      {code && (
        <>
          <p className="code-wrapper">
            {code} | {message}
          </p>
          <p>다시 시도해주세요.</p>
        </>
      )}
      {!code && <h1> 404 | Page not found</h1>}
    </main>
  );
}

export default NotFound;
