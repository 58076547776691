import { IButton } from './type';

const Button = ({
  children,
  className = '',
  disabled,
  onClick,
  startIcon,
  endIcon,
  variant = 'fill',
  color = 'black',
  size = '',
}: IButton) => {
  return (
    <button
      className={`button-wrapper ${className} ${variant} ${color} ${size} ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {startIcon && <div className="startIcon">{startIcon} </div>}
      {children}
      {endIcon && <div className="endIcon"> {endIcon} </div>}
    </button>
  );
};

export default Button;
