import { useToastContext } from '@context/ToastContext';

export default function useHelper() {
  const { setContents } = useToastContext();

  const copyClipBoard = (value: string) => {
    const $textarea = document.createElement('textarea');
    document.body.appendChild($textarea);
    $textarea.value = value as string;
    $textarea.select();
    setContents?.('클립보드에 복사되었습니다.');

    document.execCommand('copy');
    document.body.removeChild($textarea);
  };

  return { copyClipBoard };
}
