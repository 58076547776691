import { SetStateAction, useEffect, useState, Dispatch } from 'react';
import { CheckBoxForm } from './CheckBoxForm';
import { Button, Icon } from '@components/common';

interface IAgreeToJoinContent {
  setAgree: Dispatch<SetStateAction<boolean>>;
  setJoin: Dispatch<SetStateAction<boolean>>;
}

const AgreeToJoinContent = ({ setAgree, setJoin }: IAgreeToJoinContent) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [termsSelect, setTermsSelect] = useState({
    1: { selected: false },
    2: { selected: false },
    3: { selected: false },
    4: { selected: false },
    5: { selected: false },
    6: { selected: false },
    7: { selected: false },
    8: { selected: false },
  });
  const [viewFullDocs, setViewFullDocs] = useState({ view: false, content: '' });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (
      !termsSelect[1].selected &&
      !termsSelect[2].selected &&
      !termsSelect[3].selected &&
      !termsSelect[4].selected &&
      !termsSelect[5].selected &&
      !termsSelect[6].selected &&
      !termsSelect[7].selected &&
      !termsSelect[8].selected
    ) {
      setIsAllChecked(false);
    }
  }, [termsSelect]);

  useEffect(() => {
    if (isAllChecked) {
      setTermsSelect({
        1: { selected: true },
        2: { selected: true },
        3: { selected: true },
        4: { selected: true },
        5: { selected: true },
        6: { selected: true },
        7: { selected: true },
        8: { selected: true },
      });
    } else {
      setTermsSelect({
        1: { selected: false },
        2: { selected: false },
        3: { selected: false },
        4: { selected: false },
        5: { selected: false },
        6: { selected: false },
        7: { selected: false },
        8: { selected: false },
      });
    }
  }, [isAllChecked]);

  const handleClickNext = () => {
    if (
      termsSelect[1].selected &&
      termsSelect[2].selected &&
      termsSelect[3].selected &&
      termsSelect[4].selected &&
      termsSelect[5].selected
    ) {
      setError(false);
      setAgree(false);
      setJoin(true);
    } else {
      setError(true);
    }
  };

  return (
    <div className="sign-in__right__content-box">
      <h1 className="page-header">이용약관</h1>
      <hr />
      <div
        className={`form_border ${isAllChecked ? 'isAllChecked' : ''}`}
        onClick={() => setIsAllChecked(!isAllChecked)}
      >
        <CheckBoxForm title="전체 동의하기" isChecked={isAllChecked} bold />
        <span className="description">선택사항을 포함하여 모두 동의합니다.</span>
      </div>
      <div className="agree_form">
        <CheckBoxForm
          title="서비스 이용약관 동의"
          isChecked={termsSelect[1].selected}
          onClick={() =>
            setTermsSelect({ ...termsSelect, 1: { ...termsSelect[1], selected: !termsSelect[1].selected } })
          }
          require
          view
        />
        <CheckBoxForm
          title="개인정보처리방침 동의"
          isChecked={termsSelect[2].selected}
          onClick={() =>
            setTermsSelect({ ...termsSelect, 2: { ...termsSelect[2], selected: !termsSelect[2].selected } })
          }
          require
          view
        />
        <CheckBoxForm
          title="만 14세 이상 동의"
          isChecked={termsSelect[3].selected}
          onClick={() =>
            setTermsSelect({ ...termsSelect, 3: { ...termsSelect[3], selected: !termsSelect[3].selected } })
          }
          require
          view
        />
        <CheckBoxForm
          title="고유식별정보처리 동의"
          isChecked={termsSelect[4].selected}
          onClick={() =>
            setTermsSelect({ ...termsSelect, 4: { ...termsSelect[4], selected: !termsSelect[4].selected } })
          }
          require
          view
        />
        <CheckBoxForm
          title="통신사 이용약관 동의"
          isChecked={termsSelect[5].selected}
          onClick={() =>
            setTermsSelect({ ...termsSelect, 5: { ...termsSelect[5], selected: !termsSelect[5].selected } })
          }
          require
          view
        />
        <CheckBoxForm
          title="개인정보 제3자 제공 동의"
          isChecked={termsSelect[6].selected}
          onClick={() =>
            setTermsSelect({ ...termsSelect, 6: { ...termsSelect[6], selected: !termsSelect[6].selected } })
          }
          select
          view
        />
        <CheckBoxForm
          title="마케팅 정보 수신 동의"
          isChecked={termsSelect[7].selected}
          onClick={() =>
            setTermsSelect({ ...termsSelect, 7: { ...termsSelect[7], selected: !termsSelect[7].selected } })
          }
          select
          view
        />
        <CheckBoxForm
          title="맞춤형 알림 제공 동의"
          isChecked={termsSelect[8].selected}
          onClick={() =>
            setTermsSelect({ ...termsSelect, 8: { ...termsSelect[8], selected: !termsSelect[8].selected } })
          }
          select
          view
        />
      </div>
      {error ? <span className="error">필수 항목 미동의 시 서비스를 이용하실 수 없습니다.</span> : null}
      <div className="button_wrapper">
        <Button className="cancel_button" color="white" onClick={() => setAgree(false)}>
          취소
        </Button>
        <Button className="next_button" onClick={handleClickNext}>
          다음
          <Icon iconName="arrow_right_pointer" />
        </Button>
      </div>
    </div>
  );
};

export default AgreeToJoinContent;
