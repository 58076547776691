import { ReactComponent as symbol1_stroke } from './frame1.svg';
import { ReactComponent as symbol2_stroke } from './frame2.svg';
import { ReactComponent as symbol3_stroke } from './frame3.svg';
import { ReactComponent as symbol4_stroke } from './frame4.svg';
import { ReactComponent as symbol5_stroke } from './frame5.svg';
import { ReactComponent as symbol6_stroke } from './frame6.svg';
import { ReactComponent as symbol7_stroke } from './frame7.svg';

export const symbolSvgStroke = [
  symbol1_stroke,
  symbol2_stroke,
  symbol3_stroke,
  symbol4_stroke,
  symbol5_stroke,
  symbol6_stroke,
  symbol7_stroke,
];
