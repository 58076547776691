/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import { SidebarMenu } from './types';
import useBaseInfo from '@hooks/useBaseInfo';

interface ISideBarProps {
  sidebarMenu?: SidebarMenu;
  setSidebarMenu?: Dispatch<SetStateAction<SidebarMenu>>;
}

const Sidebar = ({ sidebarMenu, setSidebarMenu }: ISideBarProps) => {
  const { menu, platform } = sidebarMenu || {};
  const { pathname } = useLocation();

  const isDeployPage = pathname === '/deploy';

  const {
    baseInfoQuery: { data },
  } = useBaseInfo({ baseInfo: isDeployPage });

  const handleMenuClick = (platform: string) => {
    platform === '유튜브' || platform === '부동산'
      ? sidebarMenu && setSidebarMenu && setSidebarMenu({ ...sidebarMenu, platform })
      : null;
  };

  useEffect(() => {
    sessionStorage.setItem('sidebarMenu', JSON.stringify(sidebarMenu));
  }, [sidebarMenu]);

  return (
    <aside className="sidebar">
      <ul className="wrapper mt38">
        {deployMenus.map((deployMenu, idx) => (
          <ul className="wrapper" key={deployMenu}>
            <div className="menu2_wrapper">
              {/*@ts-ignore*/}
              <Icon className="mr16" iconName={`side_icon_${idx}`} />
              <Button color="white" className={`${menu === '증권형 토큰' ? `font-lg` : `font-md`} sidebar__button`}>
                {deployMenu}
              </Button>
            </div>
            {deployMenu === '증권형 토큰'
              ? data?.stoBc.map((stockTokenMenu) => (
                  <span key={stockTokenMenu.codeName} className="menu_button_wrapper">
                    {stockTokenMenu.codeName === platform ? (
                      <Icon className="arrow_Right" iconName="arrowRight_black" />
                    ) : null}
                    <Button
                      color="white"
                      className={stockTokenMenu.codeName === platform ? `font-lg mb12` : `font-sm mb12`}
                      key={stockTokenMenu.codeId}
                      onClick={() => handleMenuClick(stockTokenMenu.codeName)}
                    >
                      {stockTokenMenu.codeName}
                    </Button>
                  </span>
                ))
              : null}
          </ul>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;

const deployMenus = ['증권형 토큰', '유틸리티형 토큰', '지급결제형 토큰'];
