interface IToggleButton {
  toggleButtonData: IButtonData;
  setData: React.Dispatch<IButtonData>;
}
interface IButtonData {
  first: IButtonDataItem;
  second: IButtonDataItem;
}

interface IButtonDataItem {
  contents: string;
  value: boolean;
  type: string;
}

export default function ToggleButton({ toggleButtonData: { first, second }, setData }: IToggleButton) {
  const handleRadioClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: string) => {
    e.preventDefault();
    if (type === 'first') {
      setData({ first: { ...first, value: true }, second: { ...second, value: false } });
    }

    // TODO: POC이후 해지하기 기능  disabled 제거
    if (type === 'second') {
      if (second.type === 'RESTART') {
        return;
      }
      setData({ first: { ...first, value: false }, second: { ...second, value: true } });
    }
  };
  return (
    <div className="toggle-button">
      <button className={`button-wrapper ${first.value ? 'active' : ''}`} onClick={(e) => handleRadioClick(e, 'first')}>
        <input
          type="radio"
          checked={first.value} //
          className="radio-button"
          readOnly
        />
        <p className="contents">{first.contents}</p>
      </button>
      {/* TODO: POC이후 해지하기 기능 disabled 제거 */}
      <button
        className={`button-wrapper ${second.value ? 'active' : ''} ${second.type === 'RESTART' ? 'disabled' : ''}`}
        onClick={(e) => handleRadioClick(e, 'second')}
      >
        <div>
          <input type="radio" checked={second.value} className="radio-button" readOnly />
        </div>
        <p className="contents">{second.contents}</p>
      </button>
    </div>
  );
}
