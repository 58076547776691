import MakeSymbolImg from '@components/Deploy/MakeSymbolImg';
import { Button, ConfirmModal } from '@components/common';
import { IModalData } from '@components/common/ConfirmModal/type';
import { useStoContext } from '@context/StoContext';
import { useEffect, useState } from 'react';

interface ITokenImageEditModal {
  modalData: IModalData;
  onClickClose: () => void;
}

function downloadImage(url: string) {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const fileName = 'image.png'; // 원하는 파일 이름으로 변경 가능
      return new File([blob], fileName);
    });
}

export default function TokenImageEditModal({ modalData, onClickClose }: ITokenImageEditModal) {
  const { stoItem, handleValueChange } = useStoContext();
  const [maskedImgFile, setMaskedImgFile] = useState<File>();
  const [maskedImgBase64, setMaskedImgBase64] = useState<string>('');
  useEffect(() => {
    const downloadImageFunc = async () => {
      const file = await downloadImage(stoItem?.tokenImageUrl || '');
    };
    downloadImageFunc();
  }, []);

  const handleModify = () => {
    // handleValueChange();
    console.log(maskedImgFile, 'maskedImgFile');
    console.log('update');
  };

  return (
    <ConfirmModal modalData={modalData} onClickClose={onClickClose}>
      <div className="image-edit-modal-wrapper">
        <div>
          <MakeSymbolImg
            questionHoverMessage="토큰의 메인이 되는 일종의 토큰 아이콘으로 활용됩니다. (예시: 〶)"
            symbol={stoItem?.symbol || ''}
            setMaskedImgFile={setMaskedImgFile}
            maskedImgBase64={maskedImgBase64}
            setMaskedImgBase64={setMaskedImgBase64}
          />
        </div>
        <div className="buttons">
          <Button size="small" color="outline" onClick={onClickClose}>
            취소
          </Button>
          <Button size="small" onClick={handleModify}>
            수정하기
          </Button>
        </div>
      </div>
    </ConfirmModal>
  );
}
