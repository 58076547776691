import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { useAuthContext } from '@context/AuthContext';
interface IProtectedRoute {
  children: ReactNode;
  requireAdmin?: boolean;
}

function ProtectedRoute({ children, requireAdmin = false }: IProtectedRoute) {
  const { user } = useAuthContext();

  if (user === null) {
    return <Navigate to="/sign-in" />;
  }

  if (user && user?.role === 'ROLE_USER' && requireAdmin) {
    return <Navigate to="/sign-in" />;
  }

  if (user && user?.role == 'ROLE_ADMIN' && !requireAdmin) {
    return <Navigate to="/sign-in" />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;
