import { Form } from '@components/common';
import MakeSymbolImg from '../MakeSymbolImg';
import { IDeployFormType } from './type';
import { commaSeparator } from '@utils/string';
import { deployFormValidation } from './deployFormValidation';
import useBaseInfo from '@hooks/useBaseInfo';
import { useEffect, useState } from 'react';

const TokenInfo = ({ form, setForm, isApply }: IDeployFormType) => {
  const [maskedImgFile, setMaskedImgFile] = useState<File>();
  const [maskedImgBase64, setMaskedImgBase64] = useState<string>('');
  const {
    baseInfoQuery: { data },
  } = useBaseInfo({ baseInfo: true });

  const blockChainSelectBoxItems = data?.blockchain.map((blockChain) => ({
    codeId: blockChain.blockchainId,
    codeName: blockChain.name,
  }));

  const accountOrgSelectBoxItems = data?.accountOrg;

  const changeFunc = (title: string, value?: string | File) => {
    const newValue = { ...form.tokenInfo, [title]: value };
    setForm({ ...form, tokenInfo: { ...newValue } });
  };

  useEffect(() => {
    if (maskedImgFile) {
      setForm({ ...form, tokenInfo: { ...form.tokenInfo, '심볼 이미지': maskedImgFile } });
    }
  }, [maskedImgFile]);

  return (
    <div className="token info">
      <div className="heading2">토큰 정보</div>
      <div className="line" />
      <Form
        type="select"
        title="발행 증권사"
        placeholder="토큰을 발행할 증권사를 선택해주세요."
        selectBoxItems={accountOrgSelectBoxItems}
        onChange={(title, value) => changeFunc(title, value)}
        question
        value={form.tokenInfo['발행 증권사']}
        questionHoverMessage={questionTexts['발행 증권사']}
        validationMsg={deployFormValidation('발행 증권사', JSON.parse(form.tokenInfo['발행 증권사']).codeName, isApply)}
      />
      <Form
        type="select"
        title="블록체인"
        placeholder="블록체인을 선택해주세요."
        value={form.tokenInfo['블록체인']}
        selectBoxItems={blockChainSelectBoxItems}
        onChange={(title, value) => changeFunc(title, value)}
        question
        questionHoverMessage={questionTexts['블록체인']}
        validationMsg={deployFormValidation('블록체인', JSON.parse(form.tokenInfo['블록체인']).codeName, isApply)}
      />
      <Form
        type="text"
        title="증권 유형"
        value="수익 증권"
        readOnly
        disabled
        question
        questionHoverMessage={questionTexts['증권 유형']}
        onChange={(title, value) => changeFunc(title, value)}
        validationMsg={deployFormValidation('증권 유형', form.tokenInfo['증권 유형'], isApply)}
      />
      <Form
        type="text"
        title="토큰명"
        placeholder="영문 3~20자 이내로 입력해주세요."
        question
        questionHoverMessage={questionTexts['토큰명']}
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.tokenInfo['토큰명']}
        validationMsg={deployFormValidation('토큰명', form.tokenInfo['토큰명'], isApply)}
      />
      <Form
        type="text"
        title="심볼"
        placeholder="영문 대문자 2~6자 이내로 입력해주세요."
        question
        questionHoverMessage={questionTexts['심볼']}
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.tokenInfo['심볼']}
        validationMsg={deployFormValidation('심볼', form.tokenInfo['심볼'], isApply)}
      />
      <MakeSymbolImg
        questionHoverMessage={questionTexts['심볼 이미지']}
        symbol={form.tokenInfo.심볼}
        setMaskedImgFile={setMaskedImgFile}
        setMaskedImgBase64={setMaskedImgBase64}
        maskedImgBase64={maskedImgBase64}
      />
      <Form
        type="text"
        title="토큰 발행량"
        placeholder="발행하실 최초 토큰 수량을 입력해주세요."
        question
        questionHoverMessage={questionTexts['토큰 발행량']}
        onChange={(title, value) => changeFunc(title, value)}
        value={commaSeparator(String(form.tokenInfo['토큰 발행량']).replace(/,/g, ''))}
        validationMsg={deployFormValidation('토큰 발행량', String(form.tokenInfo['토큰 발행량']), isApply)}
      />
    </div>
  );
};

export default TokenInfo;

const questionTexts = {
  '발행 증권사': '선택한 발행사를 통해 토큰에 대한 심사 및 발행, 토큰 상장이 함께 진행됩니다.',
  블록체인: '발행사 설정 시 지정된 블록체인으로 자동 설정되며 수정 및 변경이 불가능합니다.',
  '증권 유형': '실제의 자산가치를 담보하여 발행되며, 성질에 따라 유형을 선택할 수 있습니다.',
  토큰명: '발행할 토큰의 전체 이름을 설정할 수 있습니다 (예시: Bitcoin).',
  심볼: '화폐의 통화기호와 같이 토큰의 단위로 사용되며, 영어 대문자 2~6자까지 입력이 가능합니다.(예시: BTC)',
  '심볼 이미지': '토큰의 메인이 되는 일종의 토큰 아이콘으로 활용됩니다. (예시: 〶)',
  '토큰 발행량': '최초로 토큰을 생성하고 발행할 수량을 설정할 수 있습니다.',
};
