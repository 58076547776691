import SingInContent from '@components/SignIn/SignInContent';
import { useEffect } from 'react';
import { useAuthContext } from '@context/AuthContext';
import { useNavigate } from 'react-router-dom';

function SignIn() {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.accessToken) {
      navigate(-1);
    }
  }, [user]);
  return <SingInContent />;
}

export default SignIn;
