import React from 'react';

interface IHelpBox {
  children: React.ReactNode;
  type: string;
}

export default function Tooltip({ children, type }: IHelpBox) {
  return (
    <div className={`${type} tooltip`}>
      <span>{children}</span>
    </div>
  );
  3;
}
