import { Dispatch, SetStateAction } from 'react';
import { Icon, Button } from '@components/common';
interface IJoinComplete {
  setComplete: Dispatch<SetStateAction<boolean>>;
}

const JoinComplete = ({ setComplete }: IJoinComplete) => {
  return (
    <div className="sign-in__right__content-box">
      <Icon iconName="join_logo" />
      <div className="complete_title">실버마인의 회원이 되신 것을 환영합니다!</div>
      <span className="complete_content">
        지금 바로 실버마인의 다양한 서비스를 경험하고 나만의 토큰을 발행/유통해보세요.
      </span>
      <Button className="complete_button" onClick={() => setComplete(false)}>
        시작하기
      </Button>
    </div>
  );
};

export default JoinComplete;
