import { Form } from '@components/common';
import { IDeployFormType } from './type';
import { commaSeparator } from '@utils/string';
import { deployFormValidation } from './deployFormValidation';
import { useEffect, useState } from 'react';

const YoutubePlatformInfo = ({ form, setForm, isApply }: IDeployFormType) => {
  const changeFunc = (title: string, value?: string | File) => {
    const newValue = { ...form.youtubePlatform, [title]: value };
    setForm({ ...form, youtubePlatform: { ...newValue } });
  };

  return (
    <div className="platform info">
      <div className="heading2">증권 정보</div>
      <div className="line" />
      <Form
        type="text"
        title="채널 링크"
        placeholder="유튜브 채널 링크를 입력해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue="https://"
        validationMsg={deployFormValidation('채널 링크', form.youtubePlatform['채널 링크'], isApply)}
      />
      <Form
        type="text"
        title="채널명"
        placeholder="채널명을 입력해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.youtubePlatform.채널명}
        validationMsg={deployFormValidation('채널명', form.youtubePlatform['채널명'], isApply)}
      />
      <Form
        type="text"
        title="구독자 수"
        placeholder="유튜브 채널의 구독자 수를 입력해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        value={commaSeparator(String(form.youtubePlatform['구독자 수']).replace(/,/g, ''))}
        validationMsg={deployFormValidation('구독자 수', String(form.youtubePlatform['구독자 수']), isApply)}
      />
      <Form
        type="text"
        title="총 조회수"
        placeholder="유튜브 채널의 총 조회수를 입력해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        value={commaSeparator(String(form.youtubePlatform['총 조회수']).replace(/,/g, ''))}
        validationMsg={deployFormValidation('총 조회수', String(form.youtubePlatform['총 조회수']), isApply)}
      />
      <Form
        type="text"
        title="주제"
        placeholder="운영 중인 콘텐츠 주제를 입력해주세요. (ex. 게임, 뷰티...)"
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.youtubePlatform['주제']}
        validationMsg={deployFormValidation('주제', form.youtubePlatform['주제'], isApply)}
      />
      <Form
        type="text"
        title="이메일 주소"
        placeholder="정확한 이메일 주소를 입력해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.youtubePlatform['이메일 주소']}
        validationMsg={deployFormValidation('이메일 주소', form.youtubePlatform['이메일 주소'], isApply)}
      />
    </div>
  );
};

export default YoutubePlatformInfo;
