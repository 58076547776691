import { Button } from '@components/common';
import Icon from '@components/common/Icon/Icon';
import { IModalData } from '@components/common/ConfirmModal/type';
import InputToggle from '../InputToggle/InputToggle';
import { commaSeparator } from '@utils/string';
import { useStoContext } from '@context/StoContext';
import useBaseInfo from '@hooks/useBaseInfo';
import { useRef } from 'react';
import { useToastContext } from '@context/ToastContext';
import { stoTrTypeTranslator } from '@utils/translator';

interface IStockInfo {
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  modalCancelData: IModalData;
  setCancelData: React.Dispatch<React.SetStateAction<IModalData>>;
  modalModifyData: IModalData;
  setModalModifyData: React.Dispatch<React.SetStateAction<IModalData>>;
  modalRetryData: IModalData;
}

export default function StockInfo({
  setEditMode,
  editMode,
  modalCancelData,
  setCancelData,
  modalModifyData,
  setModalModifyData,
  modalRetryData,
}: IStockInfo) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setContents } = useToastContext();
  const {
    stoItem,
    handleValueChange,
    isReviewRefused,
    isReviewApproved,
    isReviewCancel,
    isRealEstate,
    isBeingReviewed,
  } = useStoContext();
  const {
    baseInfoDetailQuery: { data: property },
  } = useBaseInfo({ baseInfo: false, platformType: editMode ? (stoItem?.stoTrType as string) : '' });
  const propertyOptions = property?.bcCategory;
  const propertyTypeOptions = propertyOptions?.filter((item) => item.codeId === stoItem?.property)[0]?.data;

  const handleCancelClick = () => {
    if (editMode) {
      return setEditMode?.(false);
    }
    setCancelData({ ...modalCancelData, isOn: !modalCancelData.isOn });
  };

  const handleUploadClick = () => {
    inputRef.current && inputRef.current.click();
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    handleValueChange?.(file, 'landFile');
  };

  const handleEditClick = () => {
    if (editMode && isReviewRefused) {
      return setModalModifyData({ ...modalRetryData, isOn: !modalRetryData.isOn });
    }
    if (editMode) {
      const validationValue = { ...stoItem };
      delete validationValue.contract;
      delete validationValue.landFileName;
      delete validationValue.txHash;
      delete validationValue.status;
      delete validationValue.createTime;
      delete validationValue.createWallet;
      delete validationValue.stoReview;
      delete validationValue.tokenId;
      delete validationValue.propertyName;
      delete validationValue.propertyTypeName;
      delete validationValue.tokenImageUrl;
      delete validationValue.tokenReview;
      const isInvalid = Object.values(validationValue).includes('');
      if (isInvalid) {
        return setContents?.('모든 항목을 작성해주세요.');
      }
      return setModalModifyData({ ...modalModifyData, isOn: !modalModifyData.isOn });
    }
    setEditMode(!editMode);
  };
  return (
    <div className="stock-info-container">
      <section className={`stock-info ${isRealEstate ? 'real-estate' : ''}`}>
        <article className="page-info">
          <h3>증권 정보</h3>
          <div className="stock-type">
            <Icon iconName={!isRealEstate ? 'youtube' : 'real_estate'} className="type-symbol" />
            <span>{stoTrTypeTranslator(stoItem?.stoTrType || '')}</span>
          </div>
        </article>
        <hr style={{ margin: 0 }} />
        <article className="info-box">
          <div className="info">
            {!editMode && !isRealEstate && (
              <div className="name-wrapper">
                <p className="name">{stoItem?.channelName}</p>
              </div>
            )}
            <ul className={`meta-data real-estate`}>
              {!isRealEstate && (
                <>
                  {editMode && (
                    <InputToggle
                      className="stock-info-input"
                      editMode={editMode}
                      value={stoItem?.channelName}
                      name="channelName"
                      label="채널명"
                      type="sto"
                    />
                  )}
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    value={`${commaSeparator(stoItem?.viewCount as number)} 회`}
                    name="viewCount"
                    label="총 조회수"
                    isNum
                    type="sto"
                  />
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    value={`${commaSeparator(stoItem?.likeCount as number)} 명`}
                    name="likeCount"
                    label="구독자 수"
                    isNum
                    type="sto"
                  />
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    value={`${stoItem?.title}`}
                    name="title"
                    label="채널 주제"
                    type="sto"
                  />
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    name="channelLink"
                    value={`${stoItem?.channelLink}`}
                    label="채널 링크"
                    endIcon={<Icon iconName="copy" />}
                    type="sto"
                    clickable
                  />
                </>
              )}
              {isRealEstate && (
                <>
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    name="property"
                    value={stoItem?.propertyName}
                    label="물건 용도"
                    type="sto"
                    codeId={stoItem?.property}
                    select={propertyOptions}
                  />
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    name="propertyType"
                    value={stoItem?.propertyTypeName}
                    codeId={stoItem?.propertyType}
                    label="물건 종류"
                    type="sto"
                    select={propertyTypeOptions}
                  />
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    name="area"
                    value={stoItem?.area}
                    label="소재지"
                    type="sto"
                  />
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    name="size"
                    value={stoItem?.size}
                    label="공급/전용 면적"
                    type="sto"
                  />
                  <>
                    <input type="file" ref={inputRef} onChange={handleFileUpload} style={{ display: 'none' }} />
                    <InputToggle
                      className="stock-info-input"
                      editMode={editMode}
                      value={stoItem?.landFileName}
                      name="landFile"
                      label="건축물 대장"
                      type="sto"
                      link={stoItem?.landFile as string}
                      upload
                      clickable
                      onChange={handleUploadClick}
                      endIcon={<Icon iconName="download" />}
                    />
                  </>
                  <InputToggle
                    className="stock-info-input"
                    editMode={editMode}
                    name="owner"
                    value={stoItem?.owner}
                    label="소유자명"
                    type="sto"
                  />
                </>
              )}
              <InputToggle
                className="stock-info-input"
                editMode={editMode}
                name="email"
                value={stoItem?.email}
                label="이메일 주소"
                type="sto"
              />
            </ul>
          </div>
        </article>
      </section>
      {!isReviewApproved && !isReviewCancel && !isBeingReviewed && (
        <div className="stock-info__button-wrapper">
          <Button className="cancel-button" color="grey" onClick={handleCancelClick}>
            {editMode ? '수정 취소' : '신청 취소'}
          </Button>
          <Button
            className="edit-button"
            startIcon={<Icon iconName={editMode ? `done` : `edit`} />}
            onClick={handleEditClick}
          >
            {!editMode ? '정보 수정' : isReviewRefused ? '재심사 신청' : '수정 완료'}
          </Button>
        </div>
      )}
    </div>
  );
}
