import loading from '@assets/lottie/loading.json';
import Backdrop from '../Backdrop/Backdrop';
import { Player } from '@lottiefiles/react-lottie-player';

export default function Loading() {
  return (
    <Backdrop transparent={true}>
      <Player autoplay loop src={loading} style={{ height: '500px', width: '500px' }} />
    </Backdrop>
  );
}
