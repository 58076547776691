import token from '@assets/elements/token.png';
import information1 from '@assets/elements/information1.png';
import information2 from '@assets/elements/information2.png';
import detail1 from '@assets/elements/detail1.png';
import detail2 from '@assets/elements/detail2.png';
import detail3 from '@assets/elements/detail3.png';
import detail4 from '@assets/elements/detail4.png';
import media from '@assets/elements/media.png';
import discussion1 from '@assets/elements/discussion1.png';
import discussion2 from '@assets/elements/discussion2.png';
import { Tab } from '@components/common';
import { useState } from 'react';

const SubscriptionInfoDetail = () => {
  const [currentTab, setCurrentTab] = useState('Information');

  return (
    <div className="page_layout">
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '38px', minWidth: '932px' }}>
        <div>
          <span>청약 정보 / 토큰 정보 상세보기</span>
          <img style={{ marginTop: '24px' }} src={token} />
        </div>
        <Tab
          tabElements={['Information', 'Detail', 'Media', 'Discussion']}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        {currentTab === 'Information' ? (
          <div>
            <img src={information1} />
            <img style={{ marginTop: '24px' }} src={information2} />
          </div>
        ) : null}
        {currentTab === 'Detail' ? (
          <div>
            <img src={detail1} />
            <img style={{ marginTop: '12px' }} src={detail2} />
            <img style={{ marginTop: '12px' }} src={detail3} />
            <img style={{ marginTop: '12px' }} src={detail4} />
          </div>
        ) : null}
        {currentTab === 'Media' ? (
          <div>
            <img src={media} />
          </div>
        ) : null}
        {currentTab === 'Discussion' ? (
          <div>
            <img src={discussion1} />
            <img style={{ marginTop: '12px' }} src={discussion2} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SubscriptionInfoDetail;
