export const deployFormValidation = (type: string, value: string, apply: boolean) => {
  if (apply) {
    if (!value) {
      const lastCharacter = type.slice(-1);
      switch (lastCharacter) {
        case '형':
        case '명':
        case '볼':
        case '량':
        case '장':
        case '인':
        case '적':
          return `${type}을 입력해주세요.`;
      }
      return `${type}를 입력해주세요.`;
    }
    switch (type) {
      case '토큰명':
        const tokenRegex = /^[A-Za-z\s]{3,20}$/;
        if (!tokenRegex.test(value)) {
          return '영문 3~20자 이내로 입력해주세요.';
        }
        break;

      case '심볼':
        const symbolRegex = /^[A-Z]{2,6}$/;
        if (!symbolRegex.test(value)) {
          return '영문 대문자 2~6자 이내로 입력해주세요.';
        }
        break;

      case '토큰 발행량':
      case '구독자 수':
      case '총 조회수':
        const tokenDeployRegex = /^[0-9]+$/;
        if (!tokenDeployRegex.test(value.replace(/,/g, ''))) {
          return '숫자만 입력해주세요.';
        }
        break;

      case '채널 링크':
        const urlRegex = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;
        if (!urlRegex.test(value)) {
          return '올바른 형식의 url을 입력해주세요.';
        }
        break;

      case '이메일 주소':
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(value)) {
          return '올바른 형식의 이메일 주소를 입력해주세요.';
        }
        break;

      default:
        return;
    }
  }
};
