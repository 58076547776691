import { IconProps } from './type';
import { ReactElement } from 'react';
import * as icons from '@assets/svg';

function Icon({ iconName, className, size, onClick, color, onMouseEnter, onMouseLeave }: IconProps): ReactElement {
  // eslint-disable-next-line import/namespace
  const SVGIcon = icons[iconName];
  const widthPx = size && (typeof size === 'number' ? `${size}px` : `${size.replace('px', '')}px`);

  return (
    <SVGIcon
      className={className}
      width={widthPx}
      stroke={color}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}

export default Icon;
