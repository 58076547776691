import { api } from './api';
import { postStoRequestType, putStoRequestType } from '@components/Deploy/types';

export const fetchDeployHistories = async (page: number | null) => {
  const params = { page };
  const { data } = await api.get('/mypage/list', { params });
  return data.data;
};

export const fetchStoHistory = async (id: string | undefined) => {
  const { data } = await api.get(`/mypage/${id}/info`);
  return data.data;
};

export const deleteStoHistory = async (stoInfoId?: string) => {
  const params = { status: 'CANCEL', comment: '', stoInfoId };
  const { data } = await api.post(`/mypage/review`, params);
  return data.success ? data.data : data;
};

export const putSto = async (stoType: string, requestData: putStoRequestType, id: string) => {
  const { landFile, requestData: request } = requestData;

  const formData = new FormData();
  delete request?.landFile;
  delete request?.status;

  if (stoType === 'REALESTATE' && requestData.landFile) {
    formData.append('landFile', landFile as File);
  }
  formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));
  try {
    const { data } = await api.put(`/mypage/submit/${id}/${stoType.toLocaleLowerCase()}`, formData);
    return data;
  } catch (e) {
    const err = e as Error;
    return err.message;
  }
};

export const postSto = async (stoType: string, requestData: postStoRequestType) => {
  const { landFile, tokenImageFile, request } = requestData;
  const formData = new FormData();
  if (stoType === 'realestate') {
    formData.append('landFile', landFile);
  }
  formData.append('tokenImageFile', tokenImageFile);
  const requestString = JSON.stringify(request);
  await formData.append('request', new Blob([requestString], { type: 'application/json' }));

  try {
    const { data } = await api.post(`/mypage/submit/${stoType}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (e) {
    const err = e as Error;
    return err.message;
  }
};

export const getBaseInfo = async () => {
  try {
    const { data } = await api.get('/mypage/baseInfo');
    return data.data;
  } catch (e) {
    const err = e as Error;
    return err.message;
  }
};

export const getBaseInfoDetail = async (baseInfoType: string) => {
  const { data } = await api.get(`/mypage/baseInfo/${baseInfoType}`);
  return data.data;
};
