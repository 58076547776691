import React, { useEffect, useState } from 'react';
import { Button, ConfirmModal, Pagination } from '@components/common';
import Icon from '@components/common/Icon/Icon';
import StageLabel from '@components/MyPage/DeployHistory/StageLabel/StageLabel';
import { tokenTypeTranslator } from '@utils/translator';
import { ITokenReviewStopListItem } from '@@types/token';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import useToken from '@hooks/useToken';
import { useToastContext } from '@context/ToastContext';
import { middleEllipsis } from '@utils/string';

interface ITokenRequestList {
  activeListModal: string;
  setActiveListModal: React.Dispatch<React.SetStateAction<string>>;
  tokenId: string;
}

export default function TokenRequestList({ activeListModal, setActiveListModal, tokenId }: ITokenRequestList) {
  const [isAllClicked, setIsAllClicked] = useState(false);
  const [page, setPage] = useState(0);
  const [list, setList] = useState<ITokenReviewStopListItem[]>([]);
  const [modalData, setModalData] = useState({
    isOn: false,
    title: ``,
    contents: '',
  });

  const { id: stoInfoId } = useParams();

  const {
    getTokenHistory: { data },
  } = useToken({ tokenId, historyType: activeListModal, page });

  const { setContents } = useToastContext();

  const { cancelTokenReview } = useToken({ historyType: activeListModal, tokenId });

  const isMintList = activeListModal === 'mint';
  const isBurnList = activeListModal === 'burn';
  const isStopList = activeListModal === 'stop';

  const tableMenu = [
    { label: '번호', type: 'number' },
    { label: '날짜', type: 'timestamp' },
    { label: '구분', type: 'type' },
    { label: `${isBurnList ? '소각량' : '추가발행량'} `, type: 'amount' },
    { label: '범위', type: 'range' },
    { label: '지갑주소', type: 'wallet' },
    { label: '신청사유', type: 'reason' },
    { label: 'TX Hash', type: 'txHash' },
    { label: '심사상태', type: 'status' },
  ];

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setActiveListModal('');
  };

  const handlePageChange = (e: { selected: number }) => {
    setPage(e.selected);
  };
  const handleModalToggle = () => {
    const clickedItem = list.filter((item) => item.isClicked)[0];
    if (!clickedItem) {
      return setContents?.('선택된 아이템이 없습니다.');
    }
    setModalData({
      ...modalData,
      title: `토큰 ${tokenTypeTranslator(activeListModal)} 신청을 취소하시겠습니까?`,
      isOn: !modalData.isOn,
    });
  };

  const handleCancelItem = () => {
    const tokenReviewId = list.filter((item) => item.isClicked)[0].tokenReviewId;
    if (tokenReviewId) {
      const requestData = {
        status: 'CANCEL',
        comment: 'CANCEL',
        stoInfoId: stoInfoId,
        tokensId: tokenId,
        tokenReviewId,
      };
      cancelTokenReview.mutate(
        { requestData },
        {
          onSuccess: () => {
            setModalData({
              ...modalData,
              title: `토큰 ${tokenTypeTranslator(activeListModal)} 신청을 취소하시겠습니까?`,
              isOn: !modalData.isOn,
            });
          },
        },
      );
    }
  };

  const handleReasonToggle = (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    id?: string,
    isReasonOn?: boolean,
  ) => {
    e.stopPropagation();
    const newList = list.map((item) => {
      if (id === item.tokenReviewId) {
        return { ...item, isReasonOn: isReasonOn ? false : true };
      }
      return { ...item, isReasonOn: false };
    });
    setList(newList);
  };

  const handleItemClick = (isForAll?: boolean, id?: string, isClicked?: boolean) => {
    if (isForAll) {
      setIsAllClicked(!isAllClicked);
      return setList(list.map((item) => ({ ...item, isClicked: isAllClicked ? false : true })));
    }
    const newList = list.map((item) => {
      if (id === item.tokenReviewId) {
        return { ...item, isClicked: isClicked ? false : true };
      }
      return { ...item, isClicked: false };
    });
    setList(newList);
  };

  useEffect(() => {
    const mappedList = data?.tokenReviewList?.map((item) => ({
      ...item,
      isClicked: false,
      isReasonOn: false,
    }));
    mappedList && setList(mappedList);
    data && setPage(data?.stoInfoListPageble?.page);
  }, [data]);

  return (
    <>
      {modalData.isOn && (
        <ConfirmModal modalData={modalData} onClickClose={handleModalToggle}>
          <div className="token-request-list__buttons">
            <Button variant="outline" className="modal-button" onClick={handleModalToggle}>
              취소
            </Button>
            <Button className="modal-button" onClick={handleCancelItem}>
              신청 취소
            </Button>
          </div>
        </ConfirmModal>
      )}
      <div className="token-request-list" onClick={(e) => handleReasonToggle(e)}>
        <button className="close-icon" onClick={(e) => handleCloseModal(e)}>
          <Icon iconName="close" />
        </button>
        <h3 className="modal-header">{`토큰 ${tokenTypeTranslator(activeListModal)} 내역`}</h3>
        {list.length === 0 && <p className="token-request-list__empty-list">내역이 없습니다.</p>}
        {list.length > 0 && (
          <>
            <ul className="token-request-list__table">
              <li className="list-item header">
                <input
                  className="item checkbox all"
                  type="checkbox"
                  checked={isAllClicked}
                  onChange={() => handleItemClick(true)}
                />
                {tableMenu.map(({ label, type }) => {
                  if (type === 'amount' && isStopList) {
                    return;
                  } else if (isBurnList && type === 'range') {
                    return;
                  } else if (isMintList && (type === 'wallet' || type === 'range' || type === 'type')) {
                    return;
                  }
                  return (
                    <p key={type} className={`item ${type}`}>
                      {label}
                    </p>
                  );
                })}
              </li>
              {list.map(
                (
                  {
                    tokenReviewId,
                    status,
                    wallet,
                    comment,
                    process,
                    createTime,
                    txHash,
                    isClicked,
                    isReasonOn,
                    amount,
                  },
                  index,
                ) => (
                  <li key={tokenReviewId} className="list-item">
                    <input
                      className="item checkbox"
                      type="checkbox"
                      checked={isClicked}
                      disabled={status === 'CANCEL'}
                      onChange={() => handleItemClick(false, tokenReviewId, isClicked)}
                    />
                    <p className="item number">
                      {data && index + (data.stoInfoListPageble.size * data.stoInfoListPageble.page + 1)}
                    </p>
                    <p className="item timestamp">{dayjs(createTime).format('YYYY.MM.DD')}</p>
                    {/* : TODO: POC 이후 해지 관련 기능 추가 필요 */}
                    {isStopList && <p className="item range">정지</p>}
                    {isBurnList && <p className="item range">{status === 'MYBURN' ? '보유토큰' : '일부계정'}</p>}
                    {(isMintList || isBurnList) && <p className="item amount">{amount ? amount : 0}</p>}
                    {isStopList && <p className="item type">{status === 'ALLSTOP' ? '전체' : '일부'}</p>}
                    {/* : TODO: POC 이후 지갑 주소 엑셀파일 다운 기능 추가 필요 */}
                    {!isMintList && <p className="item wallet">{wallet ? middleEllipsis(wallet) : '-'}</p>}
                    <div className="item reason">
                      <button onClick={(e) => handleReasonToggle(e, tokenReviewId, isReasonOn)}>사유 보기</button>
                      {isReasonOn && (
                        <div className="tooltip">
                          <button
                            className="close-button"
                            onClick={(e) => handleReasonToggle(e, tokenReviewId, isReasonOn)}
                          >
                            <Icon iconName="close_white" />
                          </button>
                          <p>{comment}</p>
                        </div>
                      )}
                    </div>
                    <button className="item txHash">
                      {txHash ? (
                        <>
                          <span>{middleEllipsis(txHash)}</span>
                          <div className="copy-button">
                            <Icon iconName="copy" color="white" />
                          </div>
                        </>
                      ) : (
                        '-'
                      )}
                    </button>
                    <div className="item stage">
                      <StageLabel type={process} />
                    </div>
                  </li>
                ),
              )}
            </ul>
            {data?.stoInfoListPageble && data?.stoInfoListPageble.totalPages >= 1 && (
              <Pagination
                handlePageChange={handlePageChange}
                className="token-request-list__pagination"
                totalPage={(data?.stoInfoListPageble?.totalPages + 1) as number}
                currentPage={data?.stoInfoListPageble?.page as number}
              />
            )}
            <div className="token-request-list__buttons">
              <Button variant="outline" className="modal-button" onClick={(e) => handleCloseModal(e)}>
                닫기
              </Button>
              <Button className="modal-button" onClick={handleModalToggle}>
                신청 취소
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
