import {
  Home,
  MyPage,
  NotFound,
  SignIn,
  DeployHistory,
  DeployHistoryItem,
  PublicOffering,
  TokenList,
  Blockchain,
  Admin,
  SubscriptionInfo,
  SubscriptionInfoDetail,
  Blockchains,
  Token,
  //   Faq,
  //   Notice,
  //   ContactUs,
  //   Consultant,
  //   News,
  //   Community,
  //   AboutUs,
} from '@pages/index';
import { createBrowserRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import App from 'src/App';
import Deploy from '@pages/Deploy';

function useRouter() {
  const pageElement = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      errorElement: <NotFound />,
      children: [
        {
          path: '/',
          element: (
            <>
              <Home />
            </>
          ),
        },
        { path: 'sign-in', element: <SignIn /> },
        {
          path: 'my-page',
          element: (
            <ProtectedRoute>
              <MyPage />
            </ProtectedRoute>
          ),
        },
        {
          path: 'my-page/deploy-history',
          element: (
            <ProtectedRoute>
              <DeployHistory />
            </ProtectedRoute>
          ),
        },
        {
          path: 'deploy',
          element: (
            <ProtectedRoute>
              <Deploy />
            </ProtectedRoute>
          ),
        },
        {
          path: 'my-page/deploy-history/:id',
          element: (
            <ProtectedRoute>
              <DeployHistoryItem />
            </ProtectedRoute>
          ),
        },
        {
          path: 'admin',
          element: (
            <ProtectedRoute requireAdmin>
              <Admin />
            </ProtectedRoute>
          ),
        },
        { path: 'public-offering', element: <PublicOffering /> },
        { path: 'token-list', element: <TokenList /> },
        { path: 'blockchains', element: <Blockchains /> },
        { path: 'blockchains/:id', element: <Blockchain /> },
        { path: 'token-list', element: <TokenList /> },
        { path: 'token-list/:id', element: <Token /> },
        {
          path: 'subscription-info',
          element: <SubscriptionInfo />,
        },
        { path: 'subscription-info/detail', element: <SubscriptionInfoDetail /> },
        { path: '*', element: <NotFound /> },
        // { path: 'about-us', element: <AboutUs /> },
        // { path: 'community', element: <Community /> },
        // { path: 'support/faq', element: <Faq /> },
        // { path: 'support/contact-us', element: <ContactUs /> },
        // { path: 'support/notice', element: <Notice /> },
        // { path: 'consultant', element: <Consultant /> },
        // { path: 'news', element: <News /> },
      ],
    },
  ]);

  return pageElement;
}

export default useRouter;
