import { useEffect, useState } from 'react';
import { Pagination, Button, ConfirmModal, Loading } from '@components/common';
import Icon from '@components/common/Icon/Icon';
import { IModalData } from '@components/common/ConfirmModal/type';
import { useToastContext } from '@context/ToastContext';
import { StageLabel } from '@components/MyPage/DeployHistory';
import useAdmin from '@hooks/useAdmin';
import { IMappedApp } from '@@types/admin';
import dayjs from 'dayjs';
import { stockTypeTranslator } from '@utils/translator';
import StoHubList from './StoHubList';

export default function Admin() {
  const [page, setPage] = useState(0);
  const [list, setList] = useState<IMappedApp[]>();
  const [tab, setTab] = useState('SILVERMINE');
  const [selectedSto, setSelectedSto] = useState<string | null>(null);
  const [confirmModalData, setConfirmModalData] = useState<IModalData>({
    isOn: false,
    title: '토큰을 발행하시겠습니까?',
    contents: ``,
  });
  const [newsRegisterModalData, setNewsRegisterModalData] = useState<IModalData>({
    isOn: false,
    title: '뉴스기사 등록하기',
    contents: '',
  });

  const {
    getApplications: { data: queryData, isFetching, isLoading },
    tokenCreate,
    changeReviewStatus,
  } = useAdmin({ page });
  const { setContents } = useToastContext();

  const handleCreateClick = (id: string) => {
    setSelectedSto(id);
    handleModalToggle();
  };

  const handleModalToggle = () => {
    setConfirmModalData({
      ...confirmModalData,
      isOn: !confirmModalData.isOn,
    });
    confirmModalData.isOn && setSelectedSto(null);
  };

  const handleNewsRegisterModalToggle = () => {
    setNewsRegisterModalData({ ...newsRegisterModalData, isOn: !newsRegisterModalData.isOn });
  };

  const handleCreateConfirm = () => {
    tokenCreate.mutate(
      { stoId: selectedSto || '' },
      {
        onSuccess: () => {
          setContents?.('토큰이 발행되었습니다.');
        },
      },
    );
    handleModalToggle();
  };

  const handleDropdownOptionClick = (stoInfoId: string, status: string) => {
    changeReviewStatus.mutate({ status, stoInfoId, comment: '' });
  };

  const handlePageChange = (e: { selected: number }) => {
    setPage(e.selected);
  };

  const handleOptionsToggle = (id?: string, isOptionsOn?: boolean) => {
    const newList = list?.map((item) => {
      if (id === item.stoInfoId) {
        return { ...item, isOptionsOn: !isOptionsOn };
      }
      return item;
    });
    setList(newList);
  };

  const isTokenIssued = (service: string) => {
    if (service === 'SUCCESS') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (queryData) {
      const mappedList = queryData.stoInfoList.map((item) => ({ ...item, isOptionsOn: false }));
      setList(mappedList);
    }
  }, [queryData, page]);

  return (
    <section className="admin-page">
      {confirmModalData.isOn && (
        <ConfirmModal modalData={confirmModalData} onClickClose={handleModalToggle}>
          <Button variant="outline" className="modal-button" onClick={handleModalToggle}>
            취소
          </Button>
          <Button className="modal-button" onClick={handleCreateConfirm}>
            확인
          </Button>
        </ConfirmModal>
      )}
      {(isFetching || tokenCreate.isLoading) && <Loading />}
      {!isLoading && (
        <main className="admin-page__contents">
          <header className="admin-page__contents__header-wrapper">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {tab === 'SILVERMINE' ? (
                <>
                  <h3 className="header">토큰 심사 내역</h3>
                  <span>총 {queryData?.stoInfoListPageble.totalElements}건</span>
                </>
              ) : (
                <>
                  <h3 className="header">작성한 뉴스 내역</h3>
                  <button
                    className="register_button"
                    onClick={() => setNewsRegisterModalData({ isOn: true, title: '뉴스기사 등록하기' })}
                  >
                    뉴스기사 등록
                  </button>
                </>
              )}
            </div>
            <div className="tab_wrapper">
              <span
                className={`tab_left ${tab === 'SILVERMINE' ? 'active' : 'inactive'}`}
                onClick={() => setTab('SILVERMINE')}
              >
                SILVERMINE
              </span>
              <span
                className={`tab_right ${tab === 'STO HUB' ? 'active' : 'inactive'}`}
                onClick={() => setTab('STO HUB')}
              >
                STO HUB
              </span>
            </div>
          </header>
          {tab === 'SILVERMINE' ? (
            <>
              <ul className="table">
                <li className="list-item header">
                  {tableMenu.map(({ label, type }) => (
                    <p key={type} className={`item ${type}`}>
                      {label}
                    </p>
                  ))}
                </li>
                {list?.map(
                  (
                    {
                      stoInfoId,
                      status,
                      isOptionsOn,
                      stoTrType,
                      stockType,
                      symbol,
                      tokenName,
                      updateTime,
                      createTime,
                      email,
                      service,
                    },
                    index,
                  ) => (
                    <li key={index} className="list-item">
                      <p className="item number">
                        {queryData &&
                          index + (queryData.stoInfoListPageble.size * queryData.stoInfoListPageble.page + 1)}
                      </p>
                      <p className="item type">
                        {`${stockTypeTranslator(stockType)} / ${stoTrType === 'YOUTUBE' ? '유튜브' : '부동산'}`}
                      </p>
                      <div className="item name">
                        <p>{tokenName}</p>
                      </div>
                      <div className="item symbol">
                        <p> {symbol}</p>
                      </div>
                      <p className="item email">{email}</p>
                      <p className="item request-timestamp">{dayjs(createTime).format('YYYY.MM.DD HH:mm')}</p>
                      <p className="item review-timestamp">{dayjs(updateTime).format('YYYY.MM.DD HH:mm')}</p>
                      <div className="item review-status">
                        <button
                          className="button"
                          onClick={() => handleOptionsToggle(stoInfoId, isOptionsOn)}
                          disabled={isTokenIssued(service)}
                        >
                          <StageLabel type={status} background={false} />
                          {!isTokenIssued(service) && (
                            <Icon iconName="dropdown" className={` icon ${isOptionsOn ? 'on' : ''}`} />
                          )}
                        </button>
                        {isOptionsOn && (
                          <div className="dropdown">
                            {dropdownMenu.map((menu) => (
                              <button key={menu.type} onClick={() => handleDropdownOptionClick(stoInfoId, menu.type)}>
                                {menu.label}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                      <button
                        className={`item publish ${status !== 'REVIEW' ? 'disabled' : ''}`}
                        onClick={() => handleCreateClick(stoInfoId)}
                        disabled={status !== 'REVIEW' || isTokenIssued(service)}
                      >
                        <span>{isTokenIssued(service) ? '발행완료' : '발행하기'}</span>
                      </button>
                    </li>
                  ),
                )}
              </ul>
              <div className="pagination-wrapper">
                {
                  <Pagination
                    handlePageChange={handlePageChange}
                    totalPage={queryData?.stoInfoListPageble.totalPages || 0}
                    currentPage={page || 0}
                  />
                }
              </div>
            </>
          ) : null}
          {tab === 'STO HUB' ? (
            <StoHubList
              modalData={newsRegisterModalData}
              onClickClose={handleNewsRegisterModalToggle}
              setNewsRegisterModalData={setNewsRegisterModalData}
            />
          ) : null}
        </main>
      )}
    </section>
  );
}
const tableMenu = [
  { label: '번호', type: 'number' },
  { label: '발행 유형', type: 'type' },
  { label: '토큰명', type: 'name' },
  { label: '심볼', type: 'symbol' },
  { label: '이메일 계정', type: 'email' },
  { label: '신청 일자', type: 'request-timestamp' },
  { label: '심사 일자', type: 'review-timestamp' },
  { label: '심사상태', type: 'review-status' },
  { label: '토큰 발행', type: 'publish' },
];

const dropdownMenu = [
  { label: '접수 중', type: 'ING' },
  { label: '심사 중', type: 'REVIEW' },
  { label: '심사 반려', type: 'REFUSE' },
];
