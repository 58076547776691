import { deleteStoRegister, postStoRegister, putStoRegister } from '@apis/admin';
import { ConfirmModal, Form, Button, Icon } from '@components/common';
import { IModalData } from '@components/common/ConfirmModal/type';
import { useState, ChangeEvent, DragEvent, useEffect } from 'react';
import { newsRegisterFormValidation } from './newsRegisterFormValidation';

interface INewsRegisterModal {
  modalData: IModalData;
  onClickClose: () => void;
}

export interface StoRegisterRequestDataType {
  imageFile: File | '';
  request: {
    media: string;
    title: string;
    link: string;
    mediaTime: string;
    postYn?: string;
    hubNewsId?: string;
  };
}

const NewsRegisterModal = ({ modalData, onClickClose }: INewsRegisterModal) => {
  const { hubNews } = modalData;
  const [radioButton, setRadioButton] = useState(hubNews?.postYn === 'Y' ? '게시 중' : '게시 중단');
  const [newsRegisterConfirmModalData, setNewsRegisterConfirmModalData] = useState<IModalData>({
    isOn: false,
    title: '뉴스기사를 등록하시겠습니까?',
    contents: ``,
  });
  const [newsUpdateConfirmModalData, setNewsUpdateConfirmModalData] = useState<IModalData>({
    isOn: false,
    title: '뉴스기사의 게시 상태를 변경하시겠습니까?',
    contents: ``,
  });
  const [newsDeleteConfirmModalData, setNewsDeleteConfirmModalData] = useState<IModalData>({
    isOn: false,
    title: '등록된 뉴스기사를 삭제하시겠습니까?',
    contents: ``,
  });
  const [isApply, setIsApply] = useState(false);
  const [newRegisterData, setNewRegisterData] = useState({
    hubNewsId: (hubNews?.hubNewsId as string) || '',
    media: (hubNews?.media as string) || '',
    title: (hubNews?.title as string) || '',
    link: (hubNews?.link as string) || '',
    mediaTime: (hubNews?.mediaTime as string) || '',
    postYn: (hubNews?.postYn as string) || '',
  });
  const [imageFile, setImageFile] = useState<File>();
  const [, setIsDragging] = useState(false);
  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    setImageFile(e.dataTransfer.files[0]);
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  useEffect(() => {
    if (radioButton === '게시 중') {
      hubNews && setNewRegisterData({ ...newRegisterData, hubNewsId: hubNews?.hubNewsId, postYn: 'Y' });
    } else if (radioButton === '게시 중단') {
      hubNews && setNewRegisterData({ ...newRegisterData, hubNewsId: hubNews?.hubNewsId, postYn: 'N' });
    }
  }, [radioButton]);

  const changeFunc = (title: string, value?: string | File) => {
    if (title === '기사 제목') {
      setNewRegisterData({ ...newRegisterData, title: value as string });
    } else if (title === '기사 작성일') {
      setNewRegisterData({ ...newRegisterData, mediaTime: value as string });
    } else if (title === '매체명') {
      setNewRegisterData({ ...newRegisterData, media: value as string });
    } else if (title === '링크') {
      setNewRegisterData({ ...newRegisterData, link: value as string });
    }
  };

  const imageNameFunc = () => {
    if (imageFile) {
      return imageFile.name;
    } else if (hubNews) {
      return hubNews?.imageUrl.substring(hubNews?.imageUrl.lastIndexOf('/') + 1);
    } else {
      return 'jpg, png 파일을 업로드해주세요.';
    }
  };

  const handleUpload = async () => {
    const { media, title, link, mediaTime } = newRegisterData;
    const stoRegisterData = {
      imageFile: imageFile as File,
      request: {
        media,
        title,
        link,
        mediaTime,
      },
    };
    const { success } = await postStoRegister(stoRegisterData);
    if (success) {
      onClickClose();
    }
  };
  const handleUpdate = async () => {
    const { media, title, link, mediaTime, hubNewsId, postYn } = newRegisterData;
    const stoRegisterData = {
      imageFile: imageFile as File,
      request: {
        hubNewsId,
        media,
        title,
        link,
        mediaTime,
        postYn,
      },
    };
    const { success } = await putStoRegister(stoRegisterData);
    if (success) {
      onClickClose();
    }
  };
  const handleDelete = async () => {
    const { hubNewsId } = newRegisterData;
    const { success } = await deleteStoRegister(hubNewsId);
    if (success) {
      onClickClose();
    }
  };
  return (
    <>
      {newsRegisterConfirmModalData.isOn ? (
        <ConfirmModal
          className="newsRegisterConfirmModalData"
          modalData={newsRegisterConfirmModalData}
          onClickClose={() => setNewsRegisterConfirmModalData({ ...newsRegisterConfirmModalData, isOn: false })}
        >
          <Button
            variant="outline"
            className="modal-button"
            onClick={() => setNewsRegisterConfirmModalData({ ...newsRegisterConfirmModalData, isOn: false })}
          >
            취소
          </Button>
          <Button className="modal-button" onClick={handleUpload}>
            확인
          </Button>
        </ConfirmModal>
      ) : null}

      {newsUpdateConfirmModalData.isOn ? (
        <ConfirmModal
          className="newsRegisterConfirmModalData"
          modalData={newsUpdateConfirmModalData}
          onClickClose={() => setNewsUpdateConfirmModalData({ ...newsUpdateConfirmModalData, isOn: false })}
        >
          <Button
            variant="outline"
            className="modal-button"
            onClick={() => setNewsUpdateConfirmModalData({ ...newsUpdateConfirmModalData, isOn: false })}
          >
            취소
          </Button>
          <Button className="modal-button" onClick={handleUpdate}>
            확인
          </Button>
        </ConfirmModal>
      ) : null}

      {newsDeleteConfirmModalData.isOn ? (
        <ConfirmModal
          className="newsRegisterConfirmModalData"
          modalData={newsDeleteConfirmModalData}
          onClickClose={() => setNewsDeleteConfirmModalData({ ...newsDeleteConfirmModalData, isOn: false })}
        >
          <Button
            variant="outline"
            className="modal-button"
            onClick={() => setNewsDeleteConfirmModalData({ ...newsDeleteConfirmModalData, isOn: false })}
          >
            취소
          </Button>
          <Button className="modal-button" onClick={handleDelete}>
            확인
          </Button>
        </ConfirmModal>
      ) : null}

      <ConfirmModal className="confirm_modal" modalData={modalData} onClickClose={onClickClose}>
        <div className={modalData.title === '뉴스기사 수정하기' ? `news_register_modal large` : `news_register_modal`}>
          {modalData.title === '뉴스기사 수정하기' ? (
            <div className="radio_button_wrapper">
              <label className="radio_button" onClick={() => setRadioButton('게시 중')}>
                {radioButton === '게시 중' ? <Icon iconName="radio_active" /> : <Icon iconName="radio_inactive" />}
                <span className={radioButton === '게시 중' ? `active` : `inactive`}>게시 중</span>
              </label>
              <label className="radio_button" onClick={() => setRadioButton('게시 중단')}>
                {radioButton === '게시 중단' ? <Icon iconName="radio_active" /> : <Icon iconName="radio_inactive" />}
                <span className={radioButton === '게시 중단' ? `active` : `inactive`}>게시 중단</span>
              </label>
            </div>
          ) : null}
          <div
            className="upload_wrapper"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <span className="title">대표 이미지</span>
            <div className="upload_file_wrapper">
              <span className="uplopad_file_description">{imageNameFunc()}</span>
              <label className="uplopad_file_button">
                <input type="file" onChange={handleChangeFile} />
                파일 선택
              </label>
            </div>
          </div>

          <Form
            title="기사 제목"
            placeholder="뉴스기사의 제목을 입력해주세요."
            defaultValue={hubNews?.title}
            onChange={(title, value) => changeFunc(title, value)}
            validationMsg={newsRegisterFormValidation('기사 제목', newRegisterData.title, isApply)}
          />
          <div className="row_wrapper">
            <Form
              title="기사 작성일"
              placeholder="기사 작성일을 입력해주세요."
              defaultValue={hubNews?.mediaTime}
              onChange={(title, value) => changeFunc(title, value)}
              validationMsg={newsRegisterFormValidation('기사 작성일', newRegisterData.mediaTime, isApply)}
            />
            <Form
              title="매체명"
              placeholder="기사를 작성한 매체명을 입력해주세요."
              defaultValue={hubNews?.media}
              onChange={(title, value) => changeFunc(title, value)}
              validationMsg={newsRegisterFormValidation('매체명', newRegisterData.media, isApply)}
            />
          </div>
          <Form
            title="링크"
            placeholder="뉴스기사의 링크를 입력해 주세요."
            defaultValue={hubNews?.link}
            onChange={(title, value) => changeFunc(title, value)}
            validationMsg={newsRegisterFormValidation('링크', newRegisterData.link, isApply)}
          />
          {modalData.title === '뉴스기사 수정하기' ? (
            <div className="button_wrapper">
              <Button
                color="white"
                className="cancel_button"
                onClick={() => setNewsDeleteConfirmModalData({ ...newsDeleteConfirmModalData, isOn: true })}
              >
                삭제
              </Button>
              <Button
                className="upload_button"
                onClick={() => {
                  setIsApply(true);
                  if (
                    newRegisterData.title &&
                    newRegisterData.mediaTime &&
                    newRegisterData.media &&
                    newRegisterData.link
                  ) {
                    setNewsUpdateConfirmModalData({ ...newsUpdateConfirmModalData, isOn: true });
                  }
                }}
              >
                수정하기
              </Button>
            </div>
          ) : (
            <div className="button_wrapper">
              <Button color="white" className="cancel_button" onClick={onClickClose}>
                취소
              </Button>
              <Button
                className="upload_button"
                onClick={() => {
                  setIsApply(true);
                  if (
                    newRegisterData.title &&
                    newRegisterData.mediaTime &&
                    newRegisterData.media &&
                    newRegisterData.link
                  ) {
                    setNewsRegisterConfirmModalData({ ...newsRegisterConfirmModalData, isOn: true });
                  }
                }}
              >
                업로드
              </Button>
            </div>
          )}
        </div>
      </ConfirmModal>
    </>
  );
};

export default NewsRegisterModal;
