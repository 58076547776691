import Icon from '@components/common/Icon/Icon';
import { IconType } from '@components/common/Icon/type';

interface IInput {
  password?: boolean;
  placeholder?: string;
  disabled?: boolean;
  value: string;
  readOnly?: boolean;
  iconName?: IconType;
  exchange?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: { [key: string]: string };
}
const Input = ({ password, placeholder, disabled, value, iconName, readOnly, onChange, style }: IInput) => {
  return (
    <div className="sign-in-input__wrapper">
      {iconName && <Icon iconName={`${iconName}`} className="sign-in-input__logo" />}
      <input
        style={style}
        className="sign-in-input__input"
        placeholder={placeholder}
        disabled={disabled}
        type={password ? 'password' : 'text'}
        value={value}
        readOnly={readOnly}
        onChange={(e) => onChange && onChange(e)}
      />
    </div>
  );
};

export default Input;
