import { Form } from '@components/common';
import { IDeployFormType } from './type';
import useBaseInfo from '@hooks/useBaseInfo';
import { useEffect } from 'react';
import { deployFormValidation } from './deployFormValidation';
import { FormType } from './../types';
export type RealEstateBaseInfoType = {
  codeId: string;
  codeName: string;
  data?: { codeId: string; codeName: string }[];
};

const RealEstatePlatformInfo = ({ form, setForm, isApply }: IDeployFormType) => {
  const {
    baseInfoDetailQuery: { data: baseInfoDetail },
  } = useBaseInfo({ platformType: 'REALESTATE' });

  const type1 = baseInfoDetail?.bcCategory?.map((realEstateBaseInfo: RealEstateBaseInfoType) => realEstateBaseInfo);
  const type2 = type1?.filter((type1Data) => JSON.parse(form.realEstatePlatform.용도).codeId === type1Data.codeId)[0]
    ?.data;

  const changeFunc = (title: string, value?: string | File) => {
    const newValue = { ...form.realEstatePlatform, [title]: value };
    setForm((prevForm: FormType) => ({
      ...prevForm,
      realEstatePlatform: { ...prevForm.realEstatePlatform, ...newValue },
    }));
  };

  useEffect(() => {
    setForm((prevForm: FormType) => ({
      ...prevForm,
      realEstatePlatform: { ...prevForm.realEstatePlatform, 종류: JSON.stringify({ codeId: '', codeName: '' }) },
    }));
  }, [form.realEstatePlatform.용도]);

  return (
    <div className="platform info real-estate">
      <div className="heading2">증권 정보</div>
      <div className="line" />
      <Form
        type="select"
        title="용도"
        placeholder="부동산의 용도를 선택해주세요."
        selectBoxItems={type1}
        onChange={(title, value) => changeFunc(title, value)}
        value={form.realEstatePlatform['용도']}
        validationMsg={deployFormValidation('용도', JSON.parse(form.realEstatePlatform['용도']).codeName, isApply)}
      />
      <Form
        type="select"
        title="종류"
        placeholder="부동산의 종류를 선택해주세요."
        selectBoxItems={type2}
        onChange={(title, value) => changeFunc(title, value)}
        disabled={!JSON.parse(form.realEstatePlatform?.용도).codeName}
        value={form.realEstatePlatform['종류']}
        validationMsg={deployFormValidation('종류', JSON.parse(form.realEstatePlatform['종류']).codeName, isApply)}
      />
      <Form
        type="text"
        title="소재지"
        placeholder="물건의 소재지(주소)를 입력해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.realEstatePlatform['소재지']}
        validationMsg={deployFormValidation('소재지', form.realEstatePlatform['소재지'], isApply)}
      />
      <Form
        type="text"
        title="공급/전용 면적"
        placeholder="공급 면적/전용 면적을 입력해주세요. (ex. 26.65/20.6)"
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.realEstatePlatform['공급/전용 면적']}
        validationMsg={deployFormValidation('공급/전용 면적', form.realEstatePlatform['공급/전용 면적'], isApply)}
      />
      <Form
        type="text"
        title="소유자명"
        placeholder="부동산을 소유하고 있는 소유자의 이름을 입력해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.realEstatePlatform['소유자명']}
        validationMsg={deployFormValidation('소유자명', form.realEstatePlatform['소유자명'], isApply)}
      />
      <Form
        type="upload"
        title="건축물대장"
        question
        questionHoverMessage="발행 신청일로부터 최근 6개월 이내의 건축물대장 원본을 업로드해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        validationMsg={deployFormValidation('건축물대장', String(form.realEstatePlatform['건축물대장']), isApply)}
      />
      <Form
        type="text"
        title="이메일 주소"
        placeholder="정확한 이메일 주소를 입력해주세요."
        onChange={(title, value) => changeFunc(title, value)}
        defaultValue={form.realEstatePlatform['이메일 주소']}
        validationMsg={deployFormValidation('이메일 주소', form.realEstatePlatform['이메일 주소'], isApply)}
      />
    </div>
  );
};

export default RealEstatePlatformInfo;
