import Icon from '@components/common/Icon/Icon';
import DescriptionItem from './DescriptionItem';
import { Input } from './Input';
import { useEffect, useState } from 'react';
import { useAuthContext } from '@context/AuthContext';
import { Button } from '@components/common';
import { useSearchParams } from 'react-router-dom';
import { CheckBoxForm } from './CheckBoxForm';
import AgreeToJoinContent from './AgreeToJoinContent';
import JoinContent from './JoinContent';
import JoinComplete from './JoinComplete';
import FindPassword from './FindPassword';

const SingInContent = () => {
  const [userInput, setUserInput] = useState({
    email: '',
    password: '',
  });
  const [isEmailSaved, setIsEmailSaved] = useState(false);
  const [isLoginStateChecked, setIsLoginStateChecked] = useState(false);
  const [agree, setAgree] = useState(false);
  const [join, setJoin] = useState(false);
  const [complete, setComplete] = useState(false);
  const [findPassword, setFindPassword] = useState(false);
  const { invalid, signIn, setInvalid } = useAuthContext();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const description = [
    {
      title: '발행할 토큰 증권의 자산 유형별 템플릿 지원',
      body: '유튜브 채널, 부동산, 주식, 채권, 예술품 등 다양한 유무형 자산을 토큰 증권으로 발행해보세요.',
    },
    { title: '전문 컨설팅 서비스', body: '정확한 가치 측정과 맞춤 컨설팅으로 성공적인 토큰 발행, 유통을 지원합니다.' },
    {
      title: '토큰 증권 아이디어 공모',
      body: '실버마인 커뮤니티 활동에 참여하여 자유로운 투표, 건의, 응원의 메시지를 남기고 리워드도 받아보세요!',
    },
  ];

  const onChangeUserInput = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    setUserInput({ ...userInput, [key]: e.target.value });
  };

  useEffect(() => {
    setInvalid?.({ ...invalid, isInvalid: false });
  }, []);

  return (
    <div className="sign-in">
      <section className="sign-in__left">
        <div className="background" />
        <div className="text-box">
          <Icon iconName="sm_logo" className="logo" />
          <p className="pre-header">
            쉽고 간편한 토큰증권 발행 플랫폼 실버마인은 국내 최다 STO 관련 구축 사례와 기술 노하우로 미래를 바꿀 수 있는
            인프라를 제공합니다.
          </p>
          <ul>
            {description.map((item, index) => (
              <DescriptionItem key={index} title={item.title} body={item.body} />
            ))}
          </ul>
        </div>
      </section>
      <section className="sign-in__right">
        {agree ? <AgreeToJoinContent setAgree={setAgree} setJoin={setJoin} /> : null}
        {join ? <JoinContent setJoin={setJoin} setComplete={setComplete} /> : null}
        {complete ? <JoinComplete setComplete={setComplete} /> : null}
        {findPassword ? <FindPassword /> : null}
        {!agree && !join && !complete && !findPassword ? (
          <div className="sign-in__right__content-box">
            <h1 className="page-header">로그인</h1>
            <hr />
            {invalid.isInvalid && <p className="invalid-value">{invalid.message}</p>}
            {!invalid.isInvalid && code === '402' && (
              <div className="invalid-value">인증이 만료되었습니다. 다시 로그인해주세요.</div>
            )}
            <Input
              // style={userInput.email ? { backgroundColor: '#ecf3ff' } : {}}
              value={userInput.email}
              iconName="avatar"
              key={'email'}
              placeholder="Email"
              onChange={(e) => onChangeUserInput(e, 'email')}
            />
            <Input
              // style={userInput.password ? { backgroundColor: '#ecf3ff' } : {}}
              value={userInput.password}
              iconName="lock"
              placeholder="Password"
              password
              key={'password'}
              onChange={(e) => onChangeUserInput(e, 'password')}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: '12px',
                minWidth: '360px',
              }}
            >
              <CheckBoxForm isChecked={isEmailSaved} title="이메일 저장하기" setIsChecked={setIsEmailSaved} thin />
              <CheckBoxForm
                isChecked={isLoginStateChecked}
                title="로그인 상태 유지"
                setIsChecked={setIsLoginStateChecked}
                thin
              />
              <button className="sign-in__right__content-box__link" onClick={() => setFindPassword(true)}>
                비밀번호 찾기
              </button>
            </div>
            <Button className="login-button" onClick={() => signIn?.(userInput)}>
              로그인
            </Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="join_text">실버마인이 처음이신가요?</span>
              <button className="join_button" onClick={() => setAgree(true)}>
                회원가입
              </button>
            </div>
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default SingInContent;
