import { useQuery, useMutation, useQueryClient } from 'react-query';
import { fetchStoHistory, deleteStoHistory, putSto, fetchDeployHistories } from '@apis/sto';
import { IStoInfoItem, IStoInfoList } from '@@types/deployHistoryTypes';
import { putStoRequestType } from '@components/Deploy/types';

interface IUpdateStoHistory {
  type: string;
  requestData: putStoRequestType;
  id: string;
}

interface IUseSto {
  type: string;
  stoId?: string;
  page?: number | null;
}
export default function useSto({ stoId, page, type }: IUseSto) {
  const queryClient = useQueryClient();
  const getStoHistories = useQuery<IStoInfoList>(['deployHistory', page], () => fetchDeployHistories(page || null), {
    enabled: page !== null && type === 'histories',
  });

  const getStoHistory = useQuery<IStoInfoItem>(['deployHistoryItem', stoId], () => fetchStoHistory(stoId), {
    enabled: type === 'history',
  });

  const updateStoHistory = useMutation(
    ({ type, requestData, id }: IUpdateStoHistory) => putSto(type, requestData, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['deployHistoryItem', stoId]);
      },
    },
  );

  const cancelStoHistory = useMutation((id: string) => deleteStoHistory(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['deployHistoryItem', stoId]);
      queryClient.invalidateQueries(['deployHistory']);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  return { getStoHistory, cancelStoHistory, updateStoHistory, getStoHistories };
}
