import { Footer, ErrorBoundary, Navbar, Loading } from '@components/common';
import { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ILayout } from './type';
import { default as Toast } from '@components/common/Toast';
import { useToastContext } from 'src/context/ToastContext';

function Layout({ children }: ILayout) {
  const { pathname } = useLocation();
  const { toast, contents } = useToastContext();

  const backgroundColor = () => {
    switch (true) {
      case pathname.includes('/my-page'):
        return 'grey';
      case pathname === '/':
        return 'black';
      case pathname === 'sign-in':
        return '';
      default:
        return 'white';
    }
  };

  const isContentOnly = () => {
    switch (pathname) {
      case '/sign-in':
        return true;
      default:
        return false;
    }
  };
  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, []);

  return (
    <>
      {contents !== null && toast && <Toast />}
      {isContentOnly() && (
        <Suspense fallback={<Loading />}>
          <ErrorBoundary key={location.pathname}>{children}</ErrorBoundary>
        </Suspense>
      )}
      {!isContentOnly() && (
        <>
          <Navbar location={pathname === '/' ? 'home' : ''} />
          <div className={`layout ${backgroundColor()}`}>
            <Suspense fallback={<Loading />}>
              <ErrorBoundary key={location.pathname}>{children}</ErrorBoundary>
              <Footer location={pathname === '/' ? 'home' : ''} />
            </Suspense>
          </div>
        </>
      )}
    </>
  );
}

export default Layout;
