import { useEffect, useState } from 'react';
import { Icon } from '@components/common';
import { SelectBox, Upload } from '.';
import { IForm } from './type';
import { Tooltip } from '@components/MyPage/DeployHistory';
import CellphoneForm from './CellphoneForm';

const Form = ({
  className,
  title,
  type,
  disabled,
  placeholder,
  value,
  onChange,
  readOnly,
  selectBoxItems,
  question,
  questionHoverMessage,
  defaultValue,
  validationMsg,
  button,
  onClick,
}: IForm) => {
  const [inputValue, setInputValue] = useState('');
  const [questionHover, setQuestionHover] = useState(false);
  const handleChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleQuestionHoverEnter = () => {
    setQuestionHover(true);
  };
  const handleQuestionHoverLeave = () => {
    setQuestionHover(false);
  };

  useEffect(() => {
    if (onChange) {
      onChange(title, inputValue);
    }
  }, [inputValue]);

  return (
    <div className={`form ${className}`}>
      {title ? (
        <div className={`title-wrapper`}>
          <div className="wrapper">
            <div className="heading3" style={{ marginRight: '6px' }}>
              {title}
            </div>
            {question ? (
              <Icon
                className="question_icon"
                iconName="help"
                onMouseEnter={handleQuestionHoverEnter}
                onMouseLeave={handleQuestionHoverLeave}
              />
            ) : null}
            <div style={{ position: 'relative' }}>
              {questionHoverMessage && questionHover ? <Tooltip type="gray">{questionHoverMessage}</Tooltip> : null}
            </div>
          </div>
          <div className="validation_error">{validationMsg}</div>
        </div>
      ) : null}
      <div style={{ display: 'flex' }}>
        {!otherFormTypes.includes(type || '') ? (
          <div className="input_group">
            <input
              type={type}
              className={validationMsg ? `${className} validation` : className}
              disabled={disabled}
              onChange={handleChangeValue}
              placeholder={placeholder}
              value={value}
              readOnly={readOnly}
              defaultValue={defaultValue}
            />
            {button ? (
              <button className="input_button" onClick={onClick}>
                {button}
              </button>
            ) : null}
          </div>
        ) : null}

        {type === 'select' ? (
          <SelectBox
            selectBoxItems={selectBoxItems || []}
            placeholder={placeholder || ''}
            onChange={onChange}
            disabled={disabled}
            title={title || ''}
            value={value}
            validationMsg={validationMsg}
          />
        ) : null}

        {type === 'upload' ? <Upload title={title} onChange={onChange} validationMsg={validationMsg} /> : null}

        {type === 'cellphone' ? <CellphoneForm /> : null}
      </div>
    </div>
  );
};

export default Form;

const otherFormTypes = ['none', 'select', 'upload', 'cellphone'];
