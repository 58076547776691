import { Button } from '@components/common';
import Icon from '@components/common/Icon/Icon';
import ToggleButton from '@components/common/ToggleButton/ToggleButton';
import { FormToastContent, TokenStatus } from '@components/MyPage';
import { useState } from 'react';
import { useToastContext } from '@context/ToastContext';
import { useParams } from 'react-router';
import useToken from '@hooks/useToken';

interface IFormTokenStop {
  setFormModalData: React.Dispatch<React.SetStateAction<string>>;
  tokenId: string | undefined;
  service: string;
}
export default function FormTokenStop({ setFormModalData, tokenId: tokensId, service }: IFormTokenStop) {
  const [walletAddress, setWalletAddress] = useState('');
  const [comment, setComment] = useState('');
  const [actionData, setActionData] = useState({
    first: { contents: '정지하기', value: true, type: 'STOP' },
    second: { contents: '해지하기', value: false, type: 'RESTART' },
  });
  const [targetData, setTargetData] = useState({
    first: { contents: '전체 계정 정지', value: true, type: 'ALLSTOP' },
    second: { contents: '일부 계정 정지', value: false, type: 'PARTSTOP' },
  });

  const { id: stoInfoId } = useParams();
  const { postTokenReview } = useToken({ tokenId: tokensId as string, historyType: 'stop' });

  const { setContents, setTime } = useToastContext();

  const handleAddressUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (validation()) {
      targetData.first.value && setWalletAddress('');
      let status;
      let target = '';
      if (actionData.first.value) {
        status = targetData.first.value ? targetData.first.type : targetData.second.type;
      }
      if (targetData.second.value) {
        target = walletAddress;
      }
      postTokenReview.mutate(
        {
          requestData: {
            status,
            comment,
            stoInfoId,
            tokensId,
            target,
          },
        },
        {
          onSuccess: () => {
            setContents?.(<FormToastContent type="정지" />);
            setTime?.(4000);
            setFormModalData('');
          },
        },
      );
    }
  };

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setFormModalData('');
  };

  const validation = () => {
    if (!comment) {
      setContents?.('정지 사유를 입력해주세요');
      return false;
    }
    if (targetData.second.value && !walletAddress) {
      setContents?.('지갑 주소를 입력해주세요');
      return false;
    }
    return true;
  };
  return (
    <form className="form-token-stop">
      <button className="close-icon" onClick={(e) => handleCloseModal(e)}>
        <Icon iconName="close" />
      </button>
      <h1 className="modal-header">토큰 정지 신청하기</h1>
      <p className="modal-pre-header">{`정지 시 모든 동작을 멈추고 비활성화 상태로 전환되며, \n 이후 다시 활성화 상태로 변경할 수 있습니다.`}</p>
      <div className="status-wrapper">
        <TokenStatus status={service} />
      </div>
      <div className="toggle-buttons">
        <ToggleButton toggleButtonData={actionData} setData={setActionData} />
        <ToggleButton toggleButtonData={targetData} setData={setTargetData} />
      </div>
      {targetData.second.value && (
        <div className="wallet-address__wrapper">
          <div className="header-wrapper">
            <span className="header">지갑 주소</span> <span className="down-text">CSV 양식 다운로드</span>
            <div className="help-button">
              <Icon iconName="help" />
              <div className="tooltip">
                {` 토큰 보유자 중 하나 또는 여러 개의 계정을 선택하여 모든 동작을 비활성화할 수 있습니다. 하나의 계정 또는 CSV 파일 업로드를 
                통해 정지할 계정을 입력해주세요.`}
              </div>
            </div>
          </div>
          <input
            type="text"
            className="wallet-address__input"
            placeholder="모든 기능을 정지시킬 지갑 주소를 입력해주세요."
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
          />
          <Button variant="outline" className="wallet-address__button" onClick={(e) => handleAddressUpload(e)}>
            업로드
          </Button>
        </div>
      )}
      <div className="reason-textarea">
        <h3 className="header">토큰 정지 사유</h3>
        <textarea
          className="reason-textarea__textarea"
          placeholder="토큰 정지 사유를 500자 이하로 입력해주세요."
          value={comment}
          maxLength={500}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
      </div>
      <div className="submit-wrapper">
        <Button variant="outline" onClick={(e) => handleCloseModal(e)} className="close-button">
          취소
        </Button>
        <Button
          className="submit-button"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          신청하기
        </Button>
      </div>
    </form>
  );
}
