import Icon from '@components/common/Icon/Icon';
import ReactPaginate from 'react-paginate';

interface IPagination {
  totalPage: number;
  pageCount?: number;
  currentPage: number;
  className?: string;
  initialPage?: number;
  handlePageChange: (e: { selected: number }) => void;
}
export default function Pagination({
  handlePageChange,
  totalPage = 0,
  initialPage = 0,
  currentPage = 0,
  className,
}: IPagination) {
  const isFirst = currentPage === 0;
  const isLast = currentPage + 1 === totalPage;

  return (
    <ReactPaginate
      breakLabel="..."
      initialPage={initialPage}
      onPageChange={(e) => handlePageChange(e)}
      pageCount={totalPage}
      previousLabel={
        <div className={`${!isFirst ? 'active' : ''} icon-wrapper`}>
          <Icon iconName={`${isFirst ? 'pagination_arrow_left_disabled' : 'pagination_arrow_left'}`} />
        </div>
      }
      nextLabel={
        <div className={`${!isLast ? 'active' : ''} icon-wrapper`}>
          <Icon iconName={`${isLast ? 'pagination_arrow_right_disabled' : 'pagination_arrow_right'}`} />
        </div>
      }
      renderOnZeroPageCount={null}
      className={`${className} pagination`}
      activeClassName="active-page"
      pageClassName="page-item"
      pageLinkClassName="page-item-link"
      previousClassName="previous-link"
      nextClassName="next-link"
    />
  );
}
