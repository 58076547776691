import Backdrop from '../Backdrop/Backdrop';
import Icon from '../Icon/Icon';
import { IModal } from './type';

export default function ConfirmModal({ children, modalData, onClickClose, className }: IModal) {
  return (
    <Backdrop className={`${className}`}>
      <div className={`confirm-modal ${className}`}>
        <button onClick={onClickClose}>
          <Icon iconName="close" className="close-button" />
        </button>
        <h3 className="header">{modalData.title}</h3>
        {modalData.contents && <div className="contents">{modalData.contents}</div>}
        <div className="children">{children}</div>
      </div>
    </Backdrop>
  );
}
