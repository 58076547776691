import Icon from '@components/common/Icon/Icon';

interface ITokenStatus {
  status: string;
}
export default function TokenStatus({ status }: ITokenStatus) {
  return (
    <div className="token-status">
      <span>토큰 상태:</span>
      <div className="status-box">
        <Icon iconName="token_status_active" />
        <span className="label">활성화</span>
      </div>
    </div>
  );
}
