import { useState, useEffect, useRef } from 'react';
import Icon from '../Icon/Icon';
import Form from './Form';

const CellphoneForm = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isSelectboxClick, setIsSelectboxClick] = useState(false);

  const changeFunc = (title: string, value?: string | File) => {
    console.log(title, value);
  };
  const telecoms = [
    { codeId: 'SKT', codeName: 'SKT' },
    { codeId: 'KT', codeName: 'KT' },
    { codeId: 'LG U+', codeName: 'LG U+' },
    { codeId: 'SKT 알뜰폰', codeName: 'SKT 알뜰폰' },
    { codeId: 'KT 알뜰폰', codeName: 'KT 알뜰폰' },
    { codeId: 'LG U+ 알뜰폰', codeName: 'LG U+ 알뜰폰' },
  ];
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOutsideClick = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSelectboxClick(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="cellphone">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/* <div className="telecom_selectbox" onClick={() => setIsSelectboxClick(!isSelectboxClick)} ref={dropdownRef}>
          <span>SKT</span>
          <Icon iconName={isSelectboxClick ? `dropup` : `dropdown`} />
        </div>
        {isSelectboxClick ? (
          <div className="select-items cellphone">
            {telecoms.map((telecom) => (
              <li className="item cellphone" key={telecom.codeId}>
                {telecom.codeName}
              </li>
            ))}
          </div>
        ) : null} */}

        <Form title="" placeholder="숫자만 입력해주세요." button="인증번호 받기" />
      </div>
      <Form className="certification_form" title="" placeholder="인증번호 6자리를 입력해주세요." button="인증" />
    </div>
  );
};

export default CellphoneForm;
