import { getStoHubList } from '@apis/admin';
import { Pagination } from '@components/common';
import { SetStateAction, useEffect, useState, Dispatch } from 'react';
import { IModalData } from '@components/common/ConfirmModal/type';
import NewsRegisterModal from './NewsRegisterModal';
export interface IHubNews {
  contents: string;
  createTime: string;
  hubNewsId: string;
  imageUrl: string;
  link: string;
  media: string;
  mediaTime: string;
  postYn: string;
  title: string;
}

interface IHubNewsPageble {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

interface IStoHubList {
  modalData: IModalData;
  onClickClose: () => void;
  setNewsRegisterModalData: Dispatch<SetStateAction<IModalData>>;
}

const StoHubList = ({ modalData, onClickClose, setNewsRegisterModalData }: IStoHubList) => {
  const [page, setPage] = useState(0);
  const [hubNewsList, setHubNewsList] = useState<IHubNews[]>([]);
  const [hubNewsPageble, setHubNewsPageble] = useState<IHubNewsPageble>({
    page: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  });

  const handlePageChange = (e: { selected: number }) => {
    setPage(e.selected);
  };

  useEffect(() => {
    const getStoHubListFunc = async () => {
      const { data } = await getStoHubList(page);
      const { hubNews, hubNewsPageble } = data;
      setHubNewsList(hubNews);
      setHubNewsPageble(hubNewsPageble);
    };
    getStoHubListFunc();
  }, [page, modalData.isOn]);

  return (
    <>
      {modalData.isOn ? <NewsRegisterModal modalData={modalData} onClickClose={onClickClose} /> : null}
      <div className="stohublist">
        {hubNewsList.map((hubNews: IHubNews) => (
          <div
            className="newscard"
            key={hubNews.hubNewsId}
            onClick={() => setNewsRegisterModalData({ isOn: true, title: '뉴스기사 수정하기', hubNews })}
          >
            <img src={hubNews.imageUrl} />
            <div className="content">
              <div className="content__title">
                <span className="content__font1">{hubNews.mediaTime}</span>
                <span className="content__font1">{hubNews.media}</span>
              </div>
              <span className="content__content">{hubNews.title}</span>
              <div className="line" />
              <div className="content__footer">
                <span className="content__font1">{hubNews.createTime} 게시</span>
                {hubNews.postYn === 'Y' ? (
                  <span className="content__blue">게시중</span>
                ) : (
                  <span className="content__red">게시중단</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        currentPage={hubNewsPageble.page}
        totalPage={hubNewsPageble.totalPages}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default StoHubList;
