export const newsRegisterFormValidation = (type: string, value: string, apply: boolean) => {
  if (apply) {
    if (!value) {
      const lastCharacter = type.slice(-1);
      switch (lastCharacter) {
        case '명':
        case '목':
        case '일':
          return `${type}을 입력해주세요.`;
      }
      return `${type}를 입력해주세요.`;
    }
  }
};
