// 중간 줄임 처리
export const middleEllipsis = (text: string) => {
  return `${text?.slice(0, 6)}...${text?.slice(-6)}`;
};

// 숫자 세자리 콤마
export const commaSeparator = (value: number | string) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 클립보드 복사
export const copyClipBoard = (value: string) => {
  const $textarea = document.createElement('textarea');
  document.body.appendChild($textarea);
  $textarea.value = value as string;
  $textarea.select();
  document.execCommand('copy');
  document.body.removeChild($textarea);
};

export const CommaRemove = (x: string) => {
  return x.replace(/,/g, '');
};
