import { HomeContent } from '@components/Home';

import backgroundImg from '@assets/elements/mainPage2.png';
import { useEffect, useRef } from 'react';

function Home() {
  const backgroundRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (backgroundRef.current) {
      backgroundRef.current.style.backgroundImage = `url(${backgroundImg})`;
    }
  }, []);

  return (
    <>
      <div className="home" ref={backgroundRef}>
        <HomeContent />
      </div>
    </>
  );
}

export default Home;
