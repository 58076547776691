export const tokenTypeTranslator = (type: string) => {
  switch (type) {
    case 'stop':
      return '정지';
    case 'burn':
      return '소각';
    case 'mint':
      return '추가발행';
    default:
      return '잘못된 타입';
  }
};

export const statusTranslator = (type: string | undefined) => {
  switch (type) {
    case 'ING':
      return '접수중';
    case 'REVIEW':
      return '심사 중';
    case 'REFUSE':
      return '심사 반려';
    case 'APPROVAL':
      return '심사 승인';
    case 'PENDING':
      return '접수 중';
    case 'CANCEL':
      return '신청 취소';
    case 'TOKEN':
      return '토큰 발행';
    case 'FUND':
      return '공모 진행예정';
    case 'FUNDING':
      return '공모 진행 중';
    case 'FUNDED':
      return '공모 완료';
    case 'EXCHANGE':
      return '상장 신청 중';
    case 'EXCHANGING':
      return '상장 심사 중';
    case 'EXCHANGERF':
      return '상장 심사 반려';
    case 'EXCHANGEAR':
      return '상장 심사 승인';
    default:
      return 'TYPE ERROR';
  }
};
// 용어 변환
export const stockTypeTranslator = (value: string) => {
  switch (value) {
    case 'BC':
      return '수익증권';
    case 'DS':
      return '채무증권';
    case 'ES':
      return '지분증권';
    case 'ICS':
      return '투자계약증권';
    case 'DIS':
      return '파생결합증권';
    case 'SDS':
      return '증권예탹증권';
  }
};

export const stoTrTypeTranslator = (value: string) => {
  switch (value) {
    case 'YOUTUBE':
      return '증권형 토큰 / 유튜브';
    case 'REALESTATE':
      return '증권형 토큰 / 부동산';
    case 'STOCK':
      return '주식';
    case 'BOND':
      return '채권';
    case 'ETC':
      return '기타';
    default:
      value;
  }
};
