import { ReactElement, ReactNode, useEffect } from 'react';

export interface ILayout {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[];
  transparent?: boolean;
  className?: string;
}

export default function Backdrop({ children, transparent = false, className }: ILayout) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      document.body.style.cssText = '';
      window.scrollTo(0, 0);
    };
  }, []);
  return <div className={`backdrop ${transparent ? 'transparent' : ''} ${className}`}>{children}</div>;
}
