import { createContext, useContext, useState, ReactElement, ReactNode, useEffect } from 'react';

export interface IToastContextProvider {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[];
}

interface IToastContext {
  toast: boolean;
  contents?:
    | ReactElement<any, string | React.JSXElementConstructor<any>>
    | ReactElement<any, string | React.JSXElementConstructor<any>>[]
    | ReactNode
    | ReactNode[];
  setContents?: React.Dispatch<
    React.SetStateAction<
      | ReactElement<any, string | React.JSXElementConstructor<any>>
      | ReactElement<any, string | React.JSXElementConstructor<any>>[]
      | ReactNode
      | ReactNode[]
    >
  >;
  setToast?: React.Dispatch<React.SetStateAction<boolean>>;
  setTime?: React.Dispatch<React.SetStateAction<number>>;
}

const ToastContext = createContext<IToastContext>({ toast: true });

export function ToastContextProvider({ children }: IToastContextProvider) {
  const [toast, setToast] = useState(false);
  const [time, setTime] = useState<number>(1500);
  const [contents, setContents] = useState<ReactElement | ReactElement[] | ReactNode | ReactNode[]>(null);

  useEffect(() => {
    setToast(true);
    const timer = setTimeout(() => {
      setToast(false);
      setContents(null);
    }, time);
    return () => {
      clearTimeout(timer);
    };
  }, [contents]);

  return <ToastContext.Provider value={{ toast, setContents, contents, setTime }}>{children}</ToastContext.Provider>;
}

export function useToastContext() {
  return useContext(ToastContext);
}
