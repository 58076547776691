import Icon from '@common/Icon';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@common/Button';
import { useAuthContext } from '@context/AuthContext';
import Profile from '@common/Profile';
import { useEffect } from 'react';
interface INavbar {
  location: string;
}
const Navbar = ({ location }: INavbar) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const isHome = location === 'home';

  const handleSignInClick = () => {
    navigate(`/sign-in`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <nav className={`navbar ${isHome ? 'navbar__home' : ''}`}>
      <div className="navbar__content">
        <div className="nav-list-wrapper">
          <Link to={user?.role === 'ROLE_ADMIN' ? '#' : '/'} className="logo">
            <Icon iconName={isHome ? 'sm_logo_white' : 'sm_logo'} />
          </Link>
          <ul className="nav-list">
            {navMenu.map((item) => (
              <Link
                key={item.label}
                to={item.to}
                target={item.isExternal ? '_blank' : ''}
                className={`nav-list__link ${isHome ? 'nav-list__link__home' : ''}`}
              >
                <span>{item.label}</span>
              </Link>
            ))}
          </ul>
        </div>
        {user ? (
          <Profile />
        ) : (
          <Button onClick={handleSignInClick} className={`sign-in__button ${isHome ? 'sign-in__home' : ''}`} color="">
            <span className="text">로그인</span>
          </Button>
        )}
      </div>
    </nav>
  );
};

const navMenu = [
  { label: '토큰 증권 발행', to: '/deploy', isExternal: false },
  { label: '청약 정보', to: '/subscription-info', isExternal: false },
  { label: '상장 리스트', to: '/token-list', isExternal: false },
  { label: '컨설팅', to: 'https://stohub.io', isExternal: true },
];
export default Navbar;
