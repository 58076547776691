import { ITokenRequest, ITokenCancelRequest } from '@@types/token';
import { api } from './api';

export const fetchTokenHistory = async (id: string | undefined, type: string, page = 0) => {
  const params = { page, size: 5 };
  const { data } = await api.get(`/mypage/token/${id}/${type}`, { params });
  return data.data;
};

export const postTokenRequest = async ({ requestData, type }: ITokenRequest) => {
  const targetFile = new File([], 'filename.txt', { type: 'text/plain' });
  const formData = new FormData();
  const requestString = JSON.stringify(requestData);
  formData.append('targetFile', targetFile);
  formData.append('request', new Blob([requestString], { type: 'application/json' }));
  const { data } = await api.post(`/mypage/token/review/${type?.toLocaleLowerCase()}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data.data;
};

export const postTokenRequestCancel = async ({ requestData }: ITokenCancelRequest) => {
  const formData = new FormData();
  const requestString = JSON.stringify(requestData);
  formData.append('request', new Blob([requestString], { type: 'application/json' }));
  const { data } = await api.post(`/mypage/token/review/cancel`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data.data;
};
