import { Button } from '@components/common';
import { SelectBox } from '@components/common/Form';
import { useStoContext } from '@context/StoContext';
import useHelper from '@hooks/useHelper';
import { CommaRemove, middleEllipsis } from '@utils/string';
import { ReactElement, ReactNode } from 'react';

interface IInputToggle {
  editMode: boolean;
  disabled?: boolean;
  label: string;
  name: string;
  value: string | number | undefined;
  codeId?: string;
  endIcon?: ReactElement | ReactElement[] | ReactNode | ReactNode[];
  type?: string;
  link?: string;
  className?: string;
  upload?: boolean;
  clickable?: boolean;
  select?: Item[];
  isNum?: boolean;
  ellipsis?: boolean;
  onChange?: (title: string, selectedItemCode: string) => void;
}

type Item = {
  codeId: string;
  codeName: string;
};

export default function InputToggle({
  editMode,
  disabled,
  label,
  value,
  name,
  endIcon,
  codeId,
  type = 'token',
  className,
  link,
  upload = false,
  ellipsis = false,
  select,
  isNum = false,
  clickable = false,
  onChange,
}: IInputToggle) {
  const { handleSelectChange, handleValueChange } = useStoContext();
  const { copyClipBoard } = useHelper();
  const handleLinkClick = () => {
    link ? window.open(`${link}`, '_blank') : copyClipBoard(value as string);
  };

  return (
    <div className={`input-toggle ${type === 'stock' ? 'stock' : ''} ${className ? className : ''} `}>
      <p className="label">{label}</p>
      {!editMode && (
        <div className={`value`} onClick={() => clickable && handleLinkClick()}>
          <span
            className={`${endIcon ? 'copy' : ''}  ${name}  ${
              name === 'channelLink' || name === 'landFile' ? 'fit-to-contents' : ''
            }`}
          >
            {value ? (ellipsis ? middleEllipsis(value as string) : value) : '-'}
          </span>
          {endIcon && (
            <button className="endIcon" onClick={handleLinkClick}>
              {endIcon}
            </button>
          )}
        </div>
      )}
      {editMode && !select && !upload && (
        <input
          type={isNum ? 'number' : 'text'}
          value={isNum ? parseFloat(CommaRemove(value as string)) : value}
          className="input"
          disabled={disabled}
          name={name}
          onChange={(e) => handleValueChange?.(e.target.value, name)}
        />
      )}
      {editMode && upload && (
        <>
          <input type="text" className="input" value={value} readOnly />
          <Button variant="outline" className="upload-button" onClick={() => onChange?.(name, (value = ''))}>
            업로드
          </Button>
        </>
      )}
      {editMode && select && (
        <SelectBox
          selectBoxItems={select}
          placeholder={value ? (value as string) : '-'}
          title={name}
          value={JSON.stringify({ codeId, codeName: value })}
          onChange={(_, selectedItemCode) => {
            handleSelectChange?.(name, selectedItemCode);
          }}
        />
      )}
    </div>
  );
}
