import React from 'react';
import { Icon } from '@components/common';
import default_symbol from '@assets/icon/default_symbol.png';

interface IImage {
  imageUrl: string;
  className?: string;
}

export default function Image({ imageUrl, className }: IImage) {
  const onErrorImg = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = default_symbol;
  };

  return (
    <div className={className}>
      <div className="image-wrapper">
        {imageUrl ? (
          <>
            <img src={imageUrl} alt="img" width="90%" onError={(e) => onErrorImg(e)} />
          </>
        ) : (
          <Icon iconName="default_symbol_img" />
        )}
      </div>
    </div>
  );
}
