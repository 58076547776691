import { useEffect, useRef, useState } from 'react';
import { Icon } from '@components/common';
interface ISelectBoxProps {
  selectBoxItems: { codeId: string; codeName: string }[];
  placeholder: string;
  title: string;
  disabled?: boolean;
  onChange?: (title: string, value: string) => void;
  value?: string | number;
  validationMsg?: string;
}
type CodeType = { codeId: string; codeName: string };

const SelectBox = ({
  selectBoxItems,
  placeholder,
  title,
  disabled,
  onChange,
  value,
  validationMsg,
}: ISelectBoxProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isClick, setIsClick] = useState(false);
  const [selectedItem, setSelectedItem] = useState(value ? JSON.parse(value as string) : { codeId: '', codeName: '' });

  useEffect(() => {
    if (onChange && selectedItem && title) {
      onChange(title, JSON.stringify(selectedItem));
    }
  }, [selectedItem]);

  useEffect(() => {
    const codeName = JSON.parse(value as string).codeName;
    if (!codeName) {
      setSelectedItem(JSON.parse(value as string));
    }
  }, [value]);

  const handleClickItem = ({ codeId, codeName }: CodeType) => {
    setSelectedItem({ codeId, codeName });
    setIsClick(!isClick);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOutsideClick = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsClick(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <ul style={{ position: 'relative' }}>
      <div
        className={validationMsg ? `selectbox validation` : `selectbox`}
        onClick={() => setIsClick(!isClick)}
        ref={dropdownRef}
      >
        {selectedItem.codeName ? <span className="selected">{selectedItem.codeName}</span> : <span>{placeholder}</span>}
        <Icon className="dropdown" iconName={isClick && !disabled ? `dropup` : `dropdown`} />
      </div>
      {isClick && !disabled ? (
        <div className="select-items">
          {selectBoxItems.map((selectBoxItem) => (
            <li
              className="item"
              key={selectBoxItem.codeId}
              onClick={() => handleClickItem({ codeId: selectBoxItem.codeId, codeName: selectBoxItem.codeName })}
            >
              {selectBoxItem.codeName}
            </li>
          ))}
        </div>
      ) : null}
    </ul>
  );
};

export default SelectBox;
