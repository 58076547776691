import { useQuery } from 'react-query';
import { getBaseInfo, getBaseInfoDetail } from '@apis/sto';

interface IUseBaseInfo {
  baseInfo?: boolean;
  platformType?: string;
}

type BaseInfoData = {
  stoBc: GeneralCategory[];
  blockchain: BlockchainCategory[];
  accountOrg: GeneralCategory[];
};

type GeneralCategory = {
  codeId: string;
  codeName: string;
  data: GeneralCategory[];
};

type BlockchainCategory = {
  blockchainId: string;
  name: string;
};

type BaseInfoDetail = {
  bcCategory: GeneralCategory[];
};

export default function useBaseInfo({ baseInfo, platformType }: IUseBaseInfo) {
  const baseInfoQuery = useQuery<BaseInfoData>(['baseInfo'], () => getBaseInfo(), {
    enabled: baseInfo,
    staleTime: 10 * 60 * 1000,
  });

  const baseInfoDetailQuery = useQuery<BaseInfoDetail>(
    ['baseInfoDetail'],
    () => getBaseInfoDetail(platformType ? platformType : 'REALESTATE'),
    {
      enabled: platformType ? true : false,
      staleTime: 10 * 60 * 1000,
    },
  );

  return { baseInfoQuery, baseInfoDetailQuery };
}
