import { Icon } from '@components/common';
import { Link, useLocation } from 'react-router-dom';

interface IFooter {
  location: string;
}
const Footer = ({ location }: IFooter) => {
  const { pathname } = useLocation();

  const isDeployPage = () => {
    switch (pathname) {
      case '/deploy':
        return true;
      default:
        return false;
    }
  };
  return (
    <div
      className={`footer-container ${isDeployPage() ? 'deploy-footer' : ''}${location ? `footer__${location}` : ''}`}
    >
      <div className="footer">
        <div className="footer_content_left">
          <Icon className="logo" iconName="logo_gray" />
          <span className="font_md">The Fastest Way to</span>
          <span className="font_md">Generate Security Token</span>
          <span className="font_md2 rights">ⓒ 2023 SILVERMINE. All rights reserved.</span>
        </div>
        <div className="footer_content_right">
          <ul className="service list">
            <h3 className={`list-title ${location ? `list-title__${location}` : ''}`}>서비스</h3>
            {footerMenu.service.map((item) => (
              //   <Link key={item.label} to={item.to}>
              <span key={item.label} className="item-label">
                {item.label}
              </span>
              //   </Link>
            ))}
          </ul>
          <ul className="support list">
            <h3 className={`list-title ${location ? `list-title__${location}` : ''}`}>고객지원</h3>
            {footerMenu.support.map((item) => (
              //   <Link key={item.label} to={item.to}>
              <span key={item.label} className="item-label">
                {item.label}
              </span>
              //   </Link>
            ))}
          </ul>
          <ul className="token list">
            <h3 className={`list-title ${location ? `list-title__${location}` : ''}`}>토큰</h3>
            {footerMenu.token.map((item) =>
              item.to ? (
                <Link key={item.label} to={item.to} className="link">
                  <span className="item-label">{item.label}</span>
                </Link>
              ) : (
                <span key={item.label} className="item-label">
                  {item.label}
                </span>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const footerMenu = {
  service: [
    { label: '플랫폼 소개', to: '/' },
    { label: 'News', to: '/' },
  ],
  support: [
    { label: '자주하는 질문 FAQ', to: '/support/faq' },
    { label: '1:1 문의하기', to: '/support/contact-us' },
    { label: '공지사항', to: '/support/notice' },
  ],
  token: [
    { label: '토큰 공모전', to: '' },
    { label: '토큰 발행', to: '/deploy' },
    { label: '블록체인 정보', to: '/blockchains' },
  ],
};

export default Footer;
