import { Form, Button, Icon } from '@components/common';
import { Dispatch, SetStateAction } from 'react';

interface IJoinContent {
  setJoin: Dispatch<SetStateAction<boolean>>;
  setComplete: Dispatch<SetStateAction<boolean>>;
}
const JoinContent = ({ setJoin, setComplete }: IJoinContent) => {
  const handleJoinComplete = () => {
    setComplete(true);
    setJoin(false);
  };

  return (
    <div className="sign-in__right__content-box">
      <h1 className="page-header">회원가입</h1>
      <span className="header_description">지금 바로 가입하고 서비스를 이용해보세요!</span>
      <hr />
      <div style={{ maxWidth: '360px' }}>
        <Form title="이메일 주소" placeholder="이메일 주소를 입력해주세요." />
        <Form
          title="비밀번호"
          type="password"
          placeholder="영문, 숫자, 특수 기호를 조합하여 최소 8자 이상 입력해주세요."
        />
        <Form title="비밀번호 확인" type="password" placeholder="비밀번호를 다시 입력해주세요." />
        <Form className="cellphone_wrapper" type="cellphone" title="휴대폰 번호" />
      </div>
      <Button className="next_button" onClick={handleJoinComplete}>
        가입하기
        <Icon iconName="arrow_right_pointer" />
      </Button>
      <span className="no_join_button" onClick={() => setJoin(false)}>
        이미 아이디가 있어요.
      </span>
    </div>
  );
};

export default JoinContent;
