import React, { Component, ErrorInfo } from 'react';
import Button from '../Button/Button';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  handleRetryClick() {
    this.setState({ hasError: false });
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also send the error to an error reporting service
    // or perform any other error handling logic here
    console.error('Error caught by error boundary:', error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <h1>잠시 후 다시 시도해주세요</h1>
          <h3>요청을 처리하는데 실패했습니다.</h3>
          <Button onClick={this.handleRetryClick} className="retry-button">
            다시 시도하기
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
