import { Link } from 'react-router-dom';
import { useAuthContext } from '@context/AuthContext';
import Icon from '../Icon/Icon';
import { useEffect, useRef } from 'react';
import { middleEllipsis } from '@utils/string';
import useHelper from '@hooks/useHelper';
import { motion } from 'framer-motion';

export default function Profile() {
  const { user, signOut, toggleProfile, setToggleProfile } = useAuthContext();
  const { copyClipBoard } = useHelper();

  const profileMenuRef = useRef<HTMLButtonElement | null>(null);
  const menu = [
    { label: '토큰 발행 내역', link: 'my-page/deploy-history' },
    { label: '프로필', link: '' },
  ];

  const handleMenuClick = (isSignOut?: boolean) => {
    setToggleProfile?.(false);
    isSignOut && signOut?.();
  };

  const handleWalletClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, walletAddress: string) => {
    e.preventDefault();
    copyClipBoard(walletAddress);
  };

  useEffect(() => {
    const handleOutsideClose = (e: MouseEvent) => {
      if (toggleProfile && !profileMenuRef?.current?.contains(e.target as HTMLButtonElement)) setToggleProfile?.(false);
    };
    document.addEventListener('click', handleOutsideClose);
    return () => document.removeEventListener('click', handleOutsideClose);
  }, [toggleProfile]);

  return (
    <div className="profile-wrapper">
      <button ref={profileMenuRef} className="profile-button" onClick={() => setToggleProfile?.(!toggleProfile)}>
        <Icon iconName="mypage" />
      </button>
      {toggleProfile && (
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 500,
            damping: 30,
          }}
          className="profile"
          style={{ x: '-50%' }}
        >
          <div className="profile__info">
            <p className="item email">{user?.email}</p>
            <button className="item wallet" onClick={(e) => handleWalletClick?.(e, user?.wallet || '')}>
              <Icon iconName="wallet" />
              <p className="wallet-address"> {middleEllipsis(user?.wallet || '')}</p>
            </button>
          </div>
          <hr />
          <ul className="profile__list">
            {user?.role === 'ROLE_USER' &&
              menu.map(({ label, link }) => (
                <li className="item" key={label}>
                  <Link to={link} className="link" onClick={() => handleMenuClick()}>
                    {label}
                  </Link>
                </li>
              ))}
            <li className="item">
              <button className="link" onClick={() => handleMenuClick(true)}>
                로그아웃
              </button>
            </li>
          </ul>
        </motion.div>
      )}
    </div>
  );
}
