import { Form, Button } from '@components/common';
import { CheckBoxForm } from './CheckBoxForm';
import { useEffect, useState } from 'react';

const FindPassword = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [termsSelectbox, setTermsSelectBox] = useState({
    1: { selected: false },
    2: { selected: false },
    3: { selected: false },
    4: { selected: false },
  });

  useEffect(() => {
    if (selectAll) {
      setTermsSelectBox({
        1: { selected: true },
        2: { selected: true },
        3: { selected: true },
        4: { selected: true },
      });
    } else {
      setTermsSelectBox({
        1: { selected: false },
        2: { selected: false },
        3: { selected: false },
        4: { selected: false },
      });
    }
  }, [selectAll]);

  useEffect(() => {
    if (
      !termsSelectbox[1].selected &&
      !termsSelectbox[2].selected &&
      !termsSelectbox[3].selected &&
      !termsSelectbox[4].selected
    ) {
      setSelectAll(false);
    }
  }, [termsSelectbox]);

  return (
    <div className="sign-in__right__content-box">
      <h1 className="page-header">비밀번호 찾기</h1>
      <hr />
      <span className="header_description">비밀번호 재설정을 위해 본인 인증이 필요합니다.</span>
      <div style={{ maxWidth: '360px' }}>
        <Form title="휴대폰 번호" type="cellphone" />
        <CheckBoxForm
          className="all_agree"
          title="전체 동의하기"
          bold2
          isChecked={selectAll}
          setIsChecked={setSelectAll}
        />
        <div className="row_sb">
          <CheckBoxForm
            title="서비스 이용약관 동의"
            isChecked={termsSelectbox[1].selected}
            onClick={() => setTermsSelectBox({ ...termsSelectbox, 1: { selected: !termsSelectbox[1].selected } })}
            require
            thin2
          />
          <CheckBoxForm
            title="고유식별정보처리동의"
            isChecked={termsSelectbox[2].selected}
            onClick={() => setTermsSelectBox({ ...termsSelectbox, 2: { selected: !termsSelectbox[2].selected } })}
            require
            thin2
          />
        </div>
        <div className="row_sb">
          <CheckBoxForm
            title="개인정보처리방침 동의"
            isChecked={termsSelectbox[3].selected}
            onClick={() => setTermsSelectBox({ ...termsSelectbox, 3: { selected: !termsSelectbox[3].selected } })}
            require
            thin2
          />
          <CheckBoxForm
            className="mr"
            title="통신사이용약관동의"
            isChecked={termsSelectbox[4].selected}
            onClick={() => setTermsSelectBox({ ...termsSelectbox, 4: { selected: !termsSelectbox[4].selected } })}
            require
            thin2
          />
        </div>
        <div className="row_sb button_wrapper mt">
          <Button color="white" className="cancel_button half">
            취소
          </Button>
          <Button className="next_button half">다음</Button>
        </div>
      </div>
    </div>
  );
};

export default FindPassword;
