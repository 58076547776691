import star from '@assets/icon/star_black.png';

interface IDescriptionItem {
  title: string;
  body: string;
}
const DescriptionItem = ({ title, body }: IDescriptionItem) => {
  return (
    <li className="description__item">
      <div className="description__title-wrapper">
        <img className="description__icon" src={star} alt="icon" width={'16px'} />
        <h3 className="description__title">{title}</h3>
      </div>
      <p className="description__body">{body}</p>
    </li>
  );
};

export default DescriptionItem;
