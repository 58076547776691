export default function PublicOffering() {
  return (
    <div className="page_layout">
      <div
        style={{ display: 'flex', flexDirection: 'column', marginTop: '38px', marginLeft: '40px', minWidth: '932px' }}
      >
        <span>public offering</span>
      </div>
    </div>
  );
}
