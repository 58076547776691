import { Icon } from '@components/common';
import { RealEstatePlatformInfo, YoutubePlatformInfo, TokenInfo } from './DeployForm';
import { useEffect, useState } from 'react';
import { FormType } from './types';
import DeployButtonForm from './DeployButtonForm';

interface IDeployContentProps {
  platform: string;
}
const initialForm = (): FormType => {
  const savedForm = sessionStorage.getItem('formSave');
  if (savedForm) {
    return JSON.parse(savedForm);
  } else {
    return {
      tokenInfo: {
        '발행 증권사': JSON.stringify({ codeId: '', codeName: '' }),
        블록체인: JSON.stringify({ codeId: '', codeName: '' }),
        '증권 유형': 'BC',
        토큰명: '',
        심볼: '',
        '심볼 이미지': '',
        '토큰 발행량': '',
      },
      realEstatePlatform: {
        용도: JSON.stringify({ codeId: '', codeName: '' }),
        종류: JSON.stringify({ codeId: '', codeName: '' }),
        소재지: '',
        '공급/전용 면적': '',
        소유자명: '',
        건축물대장: '',
        '이메일 주소': '',
      },
      youtubePlatform: {
        '채널 링크': '',
        채널명: '',
        '구독자 수': '',
        '총 조회수': '',
        주제: '',
        '이메일 주소': '',
      },
    };
  }
};

const DeployContent = ({ platform }: IDeployContentProps) => {
  const [isApply, setIsApply] = useState(false);
  const [form, setForm] = useState<FormType>(initialForm());

  useEffect(() => {
    setIsApply(false);
  }, [platform]);

  return (
    <div className="deploy-content">
      <div className="heading1">
        <Icon iconName="youtube_color" />
        <span style={{ marginLeft: '10px' }}>{platform} ST 발행하기</span>
      </div>
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <TokenInfo form={form} setForm={setForm} isApply={isApply} />
        {platform === '유튜브' || platform === '부동산' ? (
          <div>
            {platform === '유튜브' ? <YoutubePlatformInfo form={form} setForm={setForm} isApply={isApply} /> : null}
            {platform === '부동산' ? <RealEstatePlatformInfo form={form} setForm={setForm} isApply={isApply} /> : null}
            <DeployButtonForm form={form} setIsApply={setIsApply} platform={platform} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DeployContent;
