/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable func-names */
/* eslint-disable no-bitwise */
import { Image as ImageJS } from 'image-js';
import { createCanvas } from 'canvas';

const BASE_SIZE = 640;

async function makeImage(imgBytes: ArrayBuffer, templateNum: number, borderColor = '#c1c1cc', noBorder?: boolean) {
  // 1. load image from bytes
  const originalImg = await ImageJS.load(imgBytes);
  const emptyArr = new Array(640 * 640 * 4).fill(0);
  const bg = new ImageJS(BASE_SIZE, BASE_SIZE, emptyArr, { alpha: 1 });

  // 2. resize image
  let xOffset = 0;
  let yOffset = 0;
  let resizedImg: ImageJS;
  if (originalImg.width > originalImg.height) {
    resizedImg = originalImg.resize({ width: BASE_SIZE });
    yOffset = Math.round((BASE_SIZE - resizedImg.height) / 2);
  } else {
    resizedImg = originalImg.resize({ height: BASE_SIZE });
    xOffset = Math.round((BASE_SIZE - resizedImg.width) / 2);
  }
  const squaredImg = await mergeImages(bg, xOffset, yOffset, resizedImg);

  // 3. make maskImage
  const maskOriginal = await makeMaskImage(templateNum);
  const mask: ImageJS = maskOriginal
    // @ts-ignore
    .gray()
    .mask({ threshold: 0.1, useAlpha: true });

  // 4. extract by mask
  // @ts-ignore
  const maskedImage = await squaredImg.extract(mask, { position: [0, 0] });
  if (noBorder) {
    return maskedImage.toBlob();
  }
  // 5. draw border image
  const borderImg = await makeBorderImage(templateNum, borderColor);
  const borderAddedImage = await mergeImages(maskedImage, 0, 0, borderImg);
  // blob 타입으로 리턴
  return borderAddedImage.toBlob();
}

async function makeMaskImage(templateNumber: number): Promise<ImageJS> {
  const borderMaskFile = pngURL[templateNumber];
  const maskUrl = `${process.env.PUBLIC_URL}/${borderMaskFile}`;

  return new Promise<ImageJS>(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open('get', maskUrl);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
      ImageJS.load(xhr.response).then((mask) => resolve(mask));
    };
    xhr.onerror = function () {
      reject(new Error(`failed by ${this.status}`));
    };
    xhr.send();
  });
}

// * 이미지로드.
async function loadBorderImage(templateNumber: number): Promise<ImageJS> {
  const borderMaskFile = lineURL[templateNumber];
  const borderUrl = `${process.env.PUBLIC_URL}/${borderMaskFile}`;

  return new Promise<ImageJS>(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open('get', borderUrl);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function () {
      ImageJS.load(xhr.response).then((mask) => resolve(mask));
    };
    xhr.onerror = function () {
      reject(new Error(`failed by ${this.status}`));
    };
    xhr.send();
  });
}

// * 색칠.
async function makeBorderImage(templateNumber: number, borderColor: string): Promise<ImageJS> {
  const rawBorderMask = await loadBorderImage(templateNumber);
  // @ts-ignore
  const mask = rawBorderMask.gray().mask({ threshold: 0.1, useAlpha: true });
  const background = new ImageJS(BASE_SIZE, BASE_SIZE, [], { alpha: 1 });
  // @ts-ignore
  const maskedBorder = background.extract(mask, { position: [0, 0] });
  const border = maskedBorder.paintMasks(mask, { color: borderColor });
  return border;
}

async function mergeImages(out: ImageJS, x: number, y: number, toInsert: ImageJS) {
  // const x=0 , y=0;
  const maxY = Math.min(out.height, y + toInsert.height);
  const maxX = Math.min(out.width, x + toInsert.width);

  if (out.bitDepth === 1) {
    for (let j = y; j < maxY; j++) {
      for (let i = x; i < maxX; i++) {
        const val = toInsert.getBitXY(i - x, j - y);
        if (val) out.setBitXY(i, j);
        else out.clearBitXY(i, j);
      }
    }
  } else if (toInsert.channels === 4) {
    // the image has alpha channel
    for (let j = y; j < maxY; j++) {
      for (let i = x; i < maxX; i++) {
        if (toInsert.getPixelXY(i - x, j - y)[3] > 127) {
          out.setPixelXY(i, j, toInsert.getPixelXY(i - x, j - y));
        }
      }
    }
  } else {
    for (let j = y; j < maxY; j++) {
      for (let i = x; i < maxX; i++) {
        const pixelXY = toInsert.getPixelXY(i - x, j - y);
        // 강제로 알파채널 값도 올리기 위한 조치. FIXME 좀 꼼수스럽다.
        pixelXY[3] = 255;
        out.setPixelXY(i, j, pixelXY);
      }
    }
  }

  return out;
}

/**
 * Read first letter for text and convert to png image
 *
 * @param text - input text
 * @param color - color of text
 * @returns string of dataUrl form of png file
 *
 */
export function textToPng(text: string, color = '#c1c1cc', bgColor = '#FFFFFF') {
  const substringLength = 1;
  let printed: string;
  if (text.length > substringLength) {
    printed = text.substring(0, substringLength);
  } else {
    printed = text;
  }

  const png = generateSync(printed, {
    bgColor,
    height: 640,
    width: 640,
    fontSize: 400,
    textAlign: 'center',
    verticalAlign: 'center',
    textColor: color,
    debug: false,
  }); // , { color: textColor, output: "dataURL" });

  return png;
}

function generateSync(text: string, config: any) {
  // width and height
  const { width, height } = config;
  const canvas = createCanvas(width, height);
  const context = canvas.getContext('2d');
  const positionX = width / 2;
  const positionY = height / 2 + 45;

  // Background color
  context.fillStyle = config.bgColor;
  context.fillRect(0, 0, width, height);

  // Set text styles
  context.font = `400 ${config.fontSize}px Pretendard`;
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  // Set text color
  context.fillStyle = config.textColor;

  // Write "KindaCode.com"
  context.fillText(text, positionX, positionY);

  return canvas.toDataURL();
  // const buffer = canvas.toBuffer("image/png");
  // return buffer;
}
export default makeImage;

const pngURL = [
  'symbolPNG/frame-1-m.png',
  'symbolPNG/frame-2-m.png',
  'symbolPNG/frame-3-m.png',
  'symbolPNG/frame-4-m.png',
  'symbolPNG/frame-5-m.png',
  'symbolPNG/frame-6-m.png',
  'symbolPNG/frame-7-m.png',
];
const lineURL = [
  'symbolPNG/frame-1.png',
  'symbolPNG/frame-2.png',
  'symbolPNG/frame-3.png',
  'symbolPNG/frame-4.png',
  'symbolPNG/frame-5.png',
  'symbolPNG/frame-6.png',
  'symbolPNG/frame-7.png',
];
