const DeployHeader = ({ menu }: IDeployHeaderProps) => {
  return (
    <>
      <div className="heading1">{menu ? `${menu} (${englishMenus[menu]}) 발행하기` : null}</div>
      <div className="heading4" style={{ marginTop: '21px' }}>
        증권 및 유무형 자산을 토큰 증권으로 발행해보세요. 실버마인은 EVM 기반의 간편 발행 도구로 자산 유형별 템플릿을
        지원합니다.
      </div>
    </>
  );
};

export default DeployHeader;

type Menu = { [key: string]: string };

const englishMenus: Menu = { '증권형 토큰': 'Security Token' };

interface IDeployHeaderProps {
  menu?: string;
}
