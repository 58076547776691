import { useState } from 'react';
import DeployContent from '@components/Deploy/DeployContent';
import DeployHeader from '@components/Deploy/DeployHeader';
import { SidebarMenu } from '@components/Deploy/Sidebar/types';
import { Sidebar } from '@components/Deploy';

const initialSidebarMenu = () => {
  const storageSidebarMenu = sessionStorage.getItem('sidebarMenu');
  if (storageSidebarMenu) {
    return JSON.parse(storageSidebarMenu);
  } else {
    return { menu: '증권형 토큰', platform: '유튜브' };
  }
};

function Deploy() {
  const [sidebarMenu, setSidebarMenu] = useState<SidebarMenu>(initialSidebarMenu());
  const { menu, platform } = sidebarMenu;

  return (
    <div className="page_layout deploy">
      <Sidebar sidebarMenu={sidebarMenu} setSidebarMenu={setSidebarMenu} />
      <div className="deploy-container">
        <DeployHeader menu={menu} />
        <DeployContent platform={platform} />
      </div>
    </div>
  );
}

export default Deploy;
