import Icon from '@components/common/Icon/Icon';

interface IFormToastContent {
  type: string;
}
export default function FormToastContent({ type }: IFormToastContent) {
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>토큰 {type} 신청이 접수되었습니다.</h3>
      <p style={{ textAlign: 'center', marginTop: '10px', marginBottom: '4px' }}>신청 결과는 영업일 기준 7일 이내에 </p>
      <div style={{ textAlign: 'center', display: 'flex' }}>
        토큰 {type} 리스트
        <div style={{ display: 'flex', margin: '0 5px 0 5px' }}>
          <Icon iconName="modal_list" />
        </div>
        에서 확인 가능합니다.
      </div>
    </div>
  );
}
