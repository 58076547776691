import { createContext, useContext, useEffect, useState, ReactElement, ReactNode } from 'react';

export interface IWindowContextProvider {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[];
}

interface IWindowContextType {
  width: number;
  breakpointTablet: number;
}

const windowContext = createContext<IWindowContextType>({ width: 0, breakpointTablet: 0 });

export function WindowContextProvider({ children }: IWindowContextProvider) {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpointTablet = 758;
  useEffect(() => {
    const resizeListener = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', resizeListener);
  });

  return <windowContext.Provider value={{ width, breakpointTablet }}>{children}</windowContext.Provider>;
}

export function useWindowContext() {
  return useContext(windowContext);
}
