import { symbolSvgStroke } from '@assets/symbolSVG';
import { Form, Icon } from '@components/common';
import { useEffect, useRef, useState, useCallback, ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { debounce } from 'lodash';
import { makeImage } from '@utils/index';
import { textToPng } from '@utils/makeImage/makeImage';

interface IMakeSymbolImgProps {
  questionHoverMessage?: string;
  symbol: string;
  setMaskedImgFile: Dispatch<SetStateAction<File | undefined>>;
  maskedImgBase64: string;
  setMaskedImgBase64: Dispatch<SetStateAction<string>>;

  // form: FormType;
  // setForm: Dispatch<SetStateAction<FormType>>;
}
const MakeSymbolImg = ({
  questionHoverMessage,
  symbol,
  setMaskedImgFile,
  maskedImgBase64,
  setMaskedImgBase64,
}: IMakeSymbolImgProps) => {
  const [svg, setSvg] = useState(0);
  const SvgStrokeReactElement = symbolSvgStroke[svg];
  const SvgStrokeRef = useRef<SVGSVGElement | null>(null);
  const [imgFile, setImgFile] = useState<File>();
  // const [maskedImgBase64, setMaskedImgBase64] = useState(tokenImgUrl);
  const borderPickerRef = useRef<HTMLInputElement>(null);
  const [borderColor, setBorderColor] = useState('#C1C1CC');
  const fillPickerRef = useRef<HTMLInputElement>(null);
  const [fillColor, setFillColor] = useState('#ffffff');
  const [isNoBorder, setIsNoBorder] = useState(false);
  const [isUploadImg, setIsUploadImg] = useState(false);
  const handleSvgClick = (idx: number) => {
    setSvg(idx);
  };

  const handleNoBorder = () => {
    setIsNoBorder(!isNoBorder);
  };

  const handleClickColorPicker = useCallback((type: 'border' | 'fill', e?: ChangeEvent<HTMLInputElement>) => {
    if (type === 'border' && borderPickerRef.current instanceof HTMLInputElement) {
      borderPickerRef.current.click();
    } else if (type === 'fill' && fillPickerRef.current instanceof HTMLInputElement) {
      fillPickerRef.current.click();
    }
    delaySetColor(type, e?.target.value);
  }, []);

  const delaySetColor = useMemo(
    () =>
      debounce((type, value) => {
        if (type === 'border') {
          return setBorderColor(value);
        } else if (type === 'fill') {
          return setFillColor(value);
        }
      }, 100),
    [],
  );

  useEffect(() => {
    if (SvgStrokeRef.current) {
      const pathElement = SvgStrokeRef.current.querySelector('path');
      if (pathElement) {
        pathElement.setAttribute('stroke', borderColor);
      }
    }
  }, [svg, borderColor]);

  //* Base64 To File
  const dataURLtoFile = (base64: string, fileName: string) => {
    const arr = base64.split(',');
    const match = arr[0].match(/:(.*?);/);

    if (!match) {
      throw new Error('Invalid base64 format');
    }

    const mime = match[1];
    const bstr = window.atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);

    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }

    const file = new File([u8arr], `${fileName}.png`, { type: mime });
    return file;
  };

  //* 기본 Masking
  useEffect(() => {
    const textToPngDataUrl = textToPng(symbol.toUpperCase(), borderColor, fillColor) as string;
    const textToPngFile = dataURLtoFile(textToPngDataUrl, symbol);
    if (!isUploadImg) {
      setImgFile(textToPngFile);
    }
  }, [symbol, borderColor, fillColor, isUploadImg]);

  const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    e.preventDefault();
    const file = e.target.files && e.target.files[0];
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImgFile(file);
        setIsUploadImg(true);
        e.target.value = '';
      };
    }
  };

  //* 이미지 파일 Masking

  const makeImageData = useCallback(async () => {
    if (imgFile) {
      const imgBuffer = await imgFile.arrayBuffer();
      const resizedImgBlob = await makeImage(imgBuffer, svg, borderColor, isNoBorder);
      // const base64Img = await blobToDataURL(resizedImgBlob);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(resizedImgBlob);
      fileReader.onload = (e) => {
        const image = new Image();
        image.src = e?.target?.result as string;
        image.onload = async () => {
          const base64Img = e?.target?.result as string;
          const file = await dataURLtoFile(base64Img, 'symbolImg');
          await setMaskedImgFile(file);
          await setMaskedImgBase64(base64Img);
        };
      };
    }
  }, [imgFile, svg, borderColor, isNoBorder]);

  useEffect(() => {
    makeImageData();
  }, [makeImageData]);

  return (
    <>
      <Form
        title="심볼 이미지"
        type="none"
        question
        questionHoverMessage={questionHoverMessage}
        // validationMsg={!form.tokenInfo['심볼 이미지']}
      />
      <div className="symbol_img_wrapper">
        <div className="symbol_img">
          {maskedImgBase64 ? (
            <img src={maskedImgBase64} alt="symbolImg" style={{ width: '80%' }} />
          ) : (
            <SvgStrokeReactElement
              ref={SvgStrokeRef}
              xmlns="http://www.w3.org/2000/svg"
              type="image/svg+xml"
              width="70px"
              height="70px"
              fill={fillColor}
            />
          )}
        </div>
        <div className="symbol_img_contents">
          <div className="content">
            <span className="title">형태</span>
            {symbolSvgStroke.map((SvgEle, idx) => {
              return (
                <li key={idx} style={{ cursor: 'pointer' }}>
                  <SvgEle fill="#ffffff" width="24px" height="24px" onClick={() => handleSvgClick(idx)} />
                </li>
              );
            })}
          </div>
          <div className="content">
            <span className="title">테두리</span>
            <div className="border_selector-wrapper">
              <input
                ref={borderPickerRef}
                type="color"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleClickColorPicker('border', e)}
                value={borderColor}
                style={{ cursor: 'pointer' }}
              />
              <div className="color_picker-wrapper">
                <Icon
                  className="color_picker"
                  iconName="color_picker"
                  onClick={() => handleClickColorPicker('border')}
                />
              </div>
              <Icon className="none_color" iconName="none_color" onClick={handleNoBorder} />
            </div>

            <span className="title">채우기</span>
            <div className="border_selector-wrapper">
              <input
                ref={fillPickerRef}
                type="color"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleClickColorPicker('fill', e)}
                value={fillColor}
                style={{ cursor: 'pointer' }}
              />
              <div className="color_picker-wrapper">
                <Icon className="color_picker" iconName="color_picker" onClick={() => handleClickColorPicker('fill')} />
              </div>
              <Icon
                className="none_color"
                iconName="none_color"
                onClick={() => {
                  setFillColor('#ffffff');
                  setIsUploadImg(false);
                }}
              />
            </div>
          </div>
          <div className="content upload">
            <span className="title upload">이미지 업로드</span>
            <label>
              <input className="input_hide" type="file" onChange={handleUploadImage} />
              <Icon className="camera" iconName="camera" />
            </label>
          </div>
          <div className="content">
            <span className="description">권장 사이즈: 640x640 px | png, jpg(jpeg) 형식</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeSymbolImg;
