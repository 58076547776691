import { StageLabel } from '@components/MyPage/DeployHistory';
import { Button, Image } from '@components/common';
import Icon from '@components/common/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import InputToggle from '../InputToggle/InputToggle';
import { TokenImageEditModal, TokenOption } from '@components/MyPage';
import { commaSeparator } from '@utils/string';
import useSto from '@hooks/useSto';
import { useParams } from 'react-router';
import { useStoContext } from '@context/StoContext';
import { stoTrTypeTranslator, stockTypeTranslator } from '@utils/translator';
import useBaseInfo from '@hooks/useBaseInfo';
import { useState } from 'react';

interface ITokenInfo {
  editMode: boolean;
  volume: number;
  service: string;
}
export default function TokenInfo({ editMode, volume, service }: ITokenInfo) {
  const [tokenImageModal, setTokenImageModal] = useState({ isOn: false, title: '심볼 이미지 수정' });
  const navigate = useNavigate();
  const { id } = useParams();
  const { stoItem, isReviewApproved, isRealEstate } = useStoContext();
  const {
    getStoHistory: { data: item },
  } = useSto({ stoId: id, type: 'history' });
  const reviewStatus = item?.token?.stoReview?.status;

  const {
    baseInfoQuery: { data },
  } = useBaseInfo({ baseInfo: editMode });

  const handleImageClick = () => {
    if (!editMode) {
      return;
    }
    setTokenImageModal({ ...tokenImageModal, isOn: !tokenImageModal.isOn });
  };

  return (
    <div className="token-info-container">
      {tokenImageModal.isOn && <TokenImageEditModal modalData={tokenImageModal} onClickClose={handleImageClick} />}
      <section className={`token-info ${isRealEstate ? 'real-estate' : ''}`}>
        <div className="page-info">
          <h3>토큰 정보</h3>
          <div>
            <StageLabel type={reviewStatus} />
          </div>
        </div>
        <hr style={{ margin: 0 }} />
        <article className="info-box">
          <div className={`token-image-wrapper`}>
            <Image imageUrl={stoItem?.tokenImageUrl || ''} className="image-box" />
            {editMode && (
              <button className="token-image-edit-button-wrapper" onClick={handleImageClick}>
                <div className="token-image-edit-button">
                  <Icon iconName="token_image_edit" className="edit-icon" />
                </div>
              </button>
            )}
          </div>

          <div className={`info ${editMode ? 'edit' : ''}`}>
            <div className="name-wrapper">
              <p className="name">{stoItem?.tokenName}</p>
              <p className="symbol">{stoItem?.symbol}</p>
            </div>
            <ul className={`meta-data ${editMode ? 'edit' : ''}`}>
              <InputToggle editMode={editMode} label={'토큰명'} name="tokenName" value={stoItem?.tokenName} />
              <InputToggle editMode={editMode} label={'심볼'} name="symbol" value={stoItem?.symbol} />
              <InputToggle
                editMode={editMode}
                disabled
                label={'토큰유형'}
                name="stoTrType"
                value={stoTrTypeTranslator(stoItem?.stoTrType || '')}
              />
              <InputToggle
                editMode={editMode}
                label={'증권유형'}
                disabled
                name="stockType"
                value={stockTypeTranslator(stoItem?.stockType as string)}
              />
              <InputToggle
                editMode={editMode}
                label={'발행사'}
                name="accountOrg"
                select={data?.accountOrg}
                codeId={stoItem?.accountOrg}
                value={stoItem?.accountOrg === 'HW' ? '한화' : '농협'}
              />
              <InputToggle
                editMode={editMode}
                label={'최초 발행량'}
                isNum
                name="volume"
                value={commaSeparator(stoItem?.volume as number)}
              />
              <InputToggle
                editMode={editMode}
                label={'블록체인'}
                disabled
                name="blockchainId"
                value={stoItem?.blockchainName}
              />
              {isReviewApproved && (
                <>
                  <InputToggle
                    editMode={editMode}
                    label={'컨트랙트'}
                    disabled
                    name="blockchainId"
                    value={stoItem?.contract}
                    clickable
                    endIcon={<Icon iconName="copy" />}
                    ellipsis
                  />
                  <InputToggle
                    editMode={editMode}
                    label={'TX Hash'}
                    disabled
                    name="blockchainId"
                    value={stoItem?.txHash}
                    clickable
                    endIcon={<Icon iconName="copy" />}
                    ellipsis
                  />
                  <InputToggle
                    editMode={editMode}
                    label={'지갑주소'}
                    disabled
                    name="blockchainId"
                    value={stoItem?.createWallet}
                    clickable
                    endIcon={<Icon iconName="copy" />}
                    ellipsis
                  />
                </>
              )}
            </ul>
          </div>
        </article>
      </section>
      {isReviewApproved && <TokenOption volume={volume} service={service} />}
      <Button className="list-button" color="grey" startIcon={<Icon iconName="list" />} onClick={() => navigate(-1)}>
        목록
      </Button>
    </div>
  );
}
