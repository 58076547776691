import { Tooltip } from '@components/MyPage/DeployHistory';
import { statusTranslator } from '@utils/translator';

interface IStageLabel {
  background?: boolean;
  type: string | undefined;
}

export default function StageLabel({ type, background = true }: IStageLabel) {
  const tooltipContent = {
    reject: '증권사 심사 결과 발행 신청이 반려되었습니다. \n 클릭하여 사유를 확인해보세요.',
    approve: '증권사 심사 결과 발행 신청이 승인되었습니다. \n 영업일 기준 1~2일 내 토큰이 발행될 예정입니다.',
  };

  return (
    <div className={`${type} ${background ? 'background' : ''} stage-label`}>
      {(type === 'reject' || type === 'approve') && (
        <div className="tooltip-wrapper">
          {type === 'reject' && <Tooltip type={type}>{tooltipContent.reject}</Tooltip>}
          {type === 'approve' && <Tooltip type={type}>{tooltipContent.approve}</Tooltip>}
        </div>
      )}
      <span>{statusTranslator(type)}</span>
    </div>
  );
  3;
}
