import { StoRegisterRequestDataType } from './../pages/Admin/NewsRegisterModal';
import axios from 'axios';
import { IReviewChangeData } from '@hooks/useAdmin';
import { apiErrorHandler, setApiConfig, setReissueToken } from '@utils/authorization';

export const adminApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  withCredentials: true,
});

adminApi.interceptors.request.use(
  (config) => {
    setApiConfig(config);
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

adminApi.interceptors.response.use(
  (response) => {
    const { success } = response.data;
    if (!success) {
      apiErrorHandler(response.data);
    }
    if (response.headers.Authorization) {
      setReissueToken(response, adminApi);
    }
    return response;
  },
  (error) => {
    return apiErrorHandler(error.response.data);
  },
);

export const fetchApplications = async (page = 0) => {
  const params = { page, size: 10 };
  const {
    data: { data },
  } = await adminApi.get(`/confirm/list`, { params });
  return data;
};

export const postTokenCreate = async (id: string) => {
  const { data } = await adminApi.post(`/confirm/${id}/create`);
  return data;
};

export const postReviewStatus = async (params: IReviewChangeData) => {
  const { data } = await adminApi.post(`/confirm/review`, params);
  return data;
};

export const getStoHubList = async (page?: number) => {
  try {
    const { data } = await adminApi.get(`/hub/news/list?page=${page}&size=8`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const postStoRegister = async (stoRegisterRequestData: StoRegisterRequestDataType) => {
  const { imageFile, request } = stoRegisterRequestData;
  const formData = new FormData();
  const requestString = JSON.stringify(request);
  formData.append('imageFile', imageFile);
  await formData.append('request', new Blob([requestString], { type: 'application/json' }));
  try {
    const { data } = await adminApi.post(`/hub/news`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const putStoRegister = async (stoRegisterRequestData: StoRegisterRequestDataType) => {
  const { imageFile, request } = stoRegisterRequestData;
  const formData = new FormData();
  const requestString = JSON.stringify(request);
  formData.append('imageFile', imageFile as File);
  await formData.append('request', new Blob([requestString], { type: 'application/json' }));
  try {
    const { data } = await adminApi.put(`/hub/news`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const deleteStoRegister = async (hubNewsId: string) => {
  try {
    const { data } = await adminApi.delete(`/hub/news`, { data: { hubNewsId } });
    return data;
  } catch (e) {
    console.error(e);
  }
};
