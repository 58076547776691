import { useToastContext } from '@context/ToastContext';
import { motion } from 'framer-motion';
export default function Toast() {
  const { contents } = useToastContext();

  return (
    <motion.div key={'toast'} initial={{ opacity: 0, transform: '0' }} animate={{ opacity: 0.9 }} className="toast">
      <div className="contents">{contents}</div>
    </motion.div>
  );
}
