import { Button } from '@components/common';
import Icon from '@components/common/Icon/Icon';
import ToggleButton from '@components/common/ToggleButton/ToggleButton';
import { useState } from 'react';
import { useToastContext } from '@context/ToastContext';
import { FormToastContent } from '@components/MyPage';
import { useParams } from 'react-router';
import useToken from '@hooks/useToken';
import { commaSeparator } from '@utils/string';
interface IFormTokenDelete {
  setFormModalData: React.Dispatch<React.SetStateAction<string>>;
  tokenId: string | undefined;
  volume: number;
}
export default function FormTokenDelete({ setFormModalData, tokenId, volume }: IFormTokenDelete) {
  const [deleteAmount, setDeleteAmount] = useState('');
  const [comment, setComment] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [targetData, setTargetData] = useState({
    first: { contents: '나의 보유 토큰 소각', value: true, type: 'MYBURN' },
    second: { contents: '일부 계정 토큰 소각', value: false, type: 'BURN' },
  });
  const { setContents, setTime } = useToastContext();
  const { id: stoInfoId } = useParams();
  const { postTokenReview } = useToken({ tokenId: tokenId as string, historyType: 'burn' });

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (validation()) {
      targetData.first.value && setWalletAddress('');
      const status = targetData.first.value ? targetData.first.type : targetData.second.type;
      const target = status === 'BURN' ? walletAddress : '';
      postTokenReview.mutate(
        {
          requestData: {
            status,
            comment,
            stoInfoId,
            tokensId: tokenId,
            target,
            amount: parseInt(0 + deleteAmount),
          },
        },
        {
          onSuccess: () => {
            setContents?.(<FormToastContent type="소각" />);
            setTime?.(4000);
            setFormModalData('');
          },
        },
      );
    }
  };

  const handleAddressUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  };

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setFormModalData('');
  };

  const validation = () => {
    if (!comment) {
      setContents?.('정지 사유를 입력해주세요');
      return false;
    }
    if (!deleteAmount) {
      setContents?.('소각할 토큰 수량을 입력해주세요');
      return false;
    }
    if (targetData.second.value && !walletAddress) {
      setContents?.('지갑 주소를 입력해주세요');
      return false;
    }
    return true;
  };

  return (
    <form className="form-token-delete">
      <button className="close-icon" onClick={(e) => handleCloseModal(e)}>
        <Icon iconName="close" />
      </button>
      <h1 className="modal-header">토큰 소각 신청하기</h1>
      <div className="toggle-buttons">
        <ToggleButton toggleButtonData={targetData} setData={setTargetData} />
      </div>
      <p className="modal-pre-header">{`총 발행량 중 유통되지 않고 보유 중인 모든 토큰을 소각할 수 있으며, \n 이미 소각한 토큰은 되돌릴 수 없습니다.`}</p>
      {targetData.second.value && (
        <div className="wallet-address__wrapper">
          <div className="header-wrapper">
            <span className="header">지갑 주소</span>
          </div>
          <input
            type="text"
            className="wallet-address__input"
            placeholder="토큰을 소각할 지갑 주소를 입력해주세요."
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
          />
          <Button variant="outline" className="wallet-address__button" onClick={(e) => handleAddressUpload(e)}>
            업로드
          </Button>
        </div>
      )}
      <div className="delete-amount__wrapper">
        <div className="header-wrapper">
          <span className="header">소각할 토큰 수량</span>
          <div className="value-box">
            <span className="label">소각 가능한 토큰 수량: </span>
            <span className="value">{commaSeparator(volume)}</span>
          </div>
        </div>
        <input
          type="number"
          className="delete-amount__input"
          placeholder="소각할 토큰 수량을 입력해주세요."
          value={deleteAmount}
          onChange={(e) => setDeleteAmount(e.target.value)}
        />
      </div>
      <div className="reason-textarea">
        <h3 className="header">토큰 소각 사유</h3>
        <textarea
          className="reason-textarea__textarea"
          placeholder="토큰 소각 사유를 500자 이하로 입력해주세요."
          value={comment}
          maxLength={500}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
      </div>
      <div className="submit-wrapper">
        <Button variant="outline" onClick={(e) => handleCloseModal(e)} className="close-button">
          취소
        </Button>
        <Button
          className="submit-button"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          신청하기
        </Button>
      </div>
    </form>
  );
}
