import { LoginUser } from '@@types/globalTypes';
import { api } from './api';
import { adminApi } from './admin';

export const signIn = async (user: LoginUser) => {
  if (!user.email && !user.password) return;
  try {
    const { data } = await api.post('/auth/login', { email: user.email, password: user.password });
    if (data.success) {
      const { accessToken, role, email, name, wallet } = data.data;
      localStorage.setItem('user', JSON.stringify({ name, email, role, accessToken, wallet }));
      api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      adminApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      return data;
    }
    return { success: false };
  } catch (e) {
    return { success: false };
  }
};
