import { ChangeEvent, useEffect, useState } from 'react';

interface IUploadProps {
  title: string;
  onChange?: (title: string, value?: string | File) => void;
  validationMsg?: string;
}
const Upload = ({ title, onChange, validationMsg }: IUploadProps) => {
  const [file, setFile] = useState<File>();
  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(title, file);
    }
  }, [file]);

  const classNames = `upload ${file ? 'after_upload' : ''} ${validationMsg ? 'validation' : ''}`;

  return (
    <div className={classNames}>
      {file && file.name ? <span>{file.name}</span> : null}
      <label className="custom_upload">
        <input type="file" onChange={handleChangeFile} />
        업로드
      </label>
    </div>
  );
};

export default Upload;
