import blockchain from '@assets/mockup/blockchain.png';
import { useNavigate } from 'react-router-dom';

export default function Blockchain() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('blockchain');
  };
  return (
    <div style={{ maxWidth: '1260px', marginTop: '52px', padding: '0px 52px' }} onClick={handleClick}>
      <img src={blockchain} alt="blockchain" width={'100%'} style={{ cursor: 'pointer' }} />
    </div>
  );
}
