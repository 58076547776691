/* eslint-disable import/no-named-as-default-member */
import '@style/scss/main.scss';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {} from 'dayjs/locale/ko';
import { Layout } from '@components/common';
import { Outlet } from 'react-router-dom';
import { WindowContextProvider } from './context/WindowContext';
import { AuthContextProvider } from './context/AuthContext';
import { StoContextProvider } from './context/StoContext';
import { ToastContextProvider } from './context/ToastContext';

dayjs.extend(relativeTime);
dayjs.locale('ko');

function App() {
  return (
    <StoContextProvider>
      <ToastContextProvider>
        <WindowContextProvider>
          <AuthContextProvider>
            <Layout>
              <Outlet />
            </Layout>
          </AuthContextProvider>
        </WindowContextProvider>
      </ToastContextProvider>
    </StoContextProvider>
  );
}

export default App;
