import { Button, Backdrop } from '@components/common';
import Icon from '@components/common/Icon/Icon';
import { IconType } from '@components/common/Icon/type';
import { useState } from 'react';
import { FormTokenStop, FormTokenAdd, FormTokenDelete, TokenStatus, TokenRequestList } from '@components/MyPage';
import useSto from '@hooks/useSto';
import { useParams } from 'react-router';
import { motion } from 'framer-motion';

export interface IModalData {
  [key: string]: boolean;
}

interface ITokenOption {
  volume: number;
  service: string;
}

export default function TokenOption({ volume, service }: ITokenOption) {
  const { id } = useParams();
  const [formModalData, setFormModalData] = useState('');
  const [activeListModal, setActiveListModal] = useState('');
  const [isHover, setIsHover] = useState({ stop: false, burn: false, mint: false });

  const handleButtonClick = (type: string, isList = false) => {
    isList ? setActiveListModal(type) : setFormModalData(type);
  };

  const {
    getStoHistory: { data: item },
  } = useSto({ stoId: id, type: 'history' });

  const tokenId = item?.token.tokenId || '';

  return (
    <section className="token-option">
      {(formModalData === 'stop' || formModalData === 'mint' || formModalData === 'burn' || activeListModal) && (
        <Backdrop>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
          >
            {formModalData === 'stop' && (
              <FormTokenStop tokenId={tokenId} setFormModalData={setFormModalData} service={service} />
            )}
            {formModalData === 'mint' && (
              <FormTokenAdd tokenId={tokenId} setFormModalData={setFormModalData} volume={volume} />
            )}
            {formModalData === 'burn' && (
              <FormTokenDelete tokenId={tokenId} setFormModalData={setFormModalData} volume={volume} />
            )}
            {activeListModal && (
              <TokenRequestList
                activeListModal={activeListModal}
                setActiveListModal={setActiveListModal}
                tokenId={tokenId as string}
              />
            )}
          </motion.div>
        </Backdrop>
      )}

      <div className="header-wrapper">
        <h3 className="header">토큰 옵션</h3>
        <TokenStatus status={service} />
      </div>
      <hr style={{ margin: 0 }} />
      <div className="buttons">
        {menu.map((item) => (
          <div
            key={item.type}
            className="token-option__button-wrapper"
            onMouseEnter={() => setIsHover({ ...isHover, [item.type]: true })}
            onMouseLeave={() => setIsHover({ ...isHover, [item.type]: false })}
          >
            <Button
              className="action-button"
              color="grey"
              startIcon={<Icon className="action-icon" iconName={`token_${item.type}` as IconType} />}
              onClick={() => handleButtonClick(item.type)}
            >
              <div className={`tooltip ${item.type}`}>
                <p>{item.tooltip}</p>
              </div>
              {item.label}
            </Button>
            <Button
              className="list-button"
              color="grey"
              startIcon={<Icon iconName="list_grey" />}
              onClick={() => handleButtonClick(item.type, true)}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
const menu = [
  {
    label: '토큰 정지 신청',
    type: 'stop',
    tooltip: '토큰 주고받기, 소각, 추가발행 등 모든\n동작을 멈추도록 제어할 수 있습니다.',
  },
  {
    label: '토큰 소각 신청',
    type: 'burn',
    tooltip: '발행 후 유통되지 않은 토큰의 일부\n또는 전체를 소각할 수 있습니다.',
  },
  {
    label: '추가발행 신청',
    type: 'mint',
    tooltip: '최초 발행된 토큰보다 더 많은 양의\n토큰을 추가로 발행할 수 있습니다.',
  },
];
