import { useMutation, useQueryClient, useQuery } from 'react-query';
import { postTokenRequest, postTokenRequestCancel, fetchTokenHistory } from '@apis/token';
import { ITokenCancelRequest, ITokenRequest, IStoInfoList } from '@@types/token';
import { useToastContext } from '@context/ToastContext';

interface IUseTokenHistories {
  tokenId: string;
  historyType: string;
  page?: number;
}

export default function useToken(
  { historyType, tokenId, page }: IUseTokenHistories = { historyType: 'stop', tokenId: '' },
) {
  const queryClient = useQueryClient();
  const type = historyType.toLocaleLowerCase();
  const { setContents } = useToastContext();

  const getTokenHistory = useQuery<IStoInfoList>(
    [`token${type}List`, tokenId, page],
    () => fetchTokenHistory(tokenId, type, page),
    {
      enabled: page !== undefined,
      suspense: false,
    },
  );

  const postTokenReview = useMutation(({ requestData }: ITokenRequest) => postTokenRequest({ requestData, type }), {
    onSuccess: () => {
      queryClient.invalidateQueries([`token${type}List`, tokenId]);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const cancelTokenReview = useMutation(
    ({ requestData }: ITokenCancelRequest) => postTokenRequestCancel({ requestData }),
    {
      onSuccess: () => {
        setContents?.('취소가 완료되었습니다.');
        queryClient.invalidateQueries([`token${type}List`, tokenId]);
      },
    },
  );

  return { postTokenReview, cancelTokenReview, getTokenHistory };
}
