import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export const reSignIn = () => {
  localStorage.removeItem('user');
  window.location.replace('/sign-in?code=402');
};

export const apiErrorHandler = (data: { code: number; msg: string; success: boolean }) => {
  const { code, msg } = data;
  if (code == 402) {
    return reSignIn();
  }
  throw new Error(msg);
};

export const setApiConfig = (config: InternalAxiosRequestConfig) => {
  const user = localStorage.getItem('user');
  if (user) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(user).accessToken}`;
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
  }
};

export const setReissueToken = (response: AxiosResponse, api: AxiosInstance) => {
  const newAccessToken = response?.headers?.Authorization;
  localStorage.removeItem('access_token');
  localStorage.setItem('access_token', JSON.stringify(newAccessToken));
  api.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(newAccessToken)}`;
};
