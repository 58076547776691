import { useEffect, useState } from 'react';
import { Button, ConfirmModal } from '@components/common';
import { useToastContext } from '@context/ToastContext';
import { TokenInfo, StockInfo } from '@components/MyPage';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import useSto from '@hooks/useSto';
import { IModalData } from '@components/common/ConfirmModal/type';
import { useStoContext } from '@context/StoContext';
import { useNavigate } from 'react-router-dom';

export default function DeployHistoryItem() {
  const { id } = useParams();
  const { setContents, setTime } = useToastContext();
  const [editMode, setEditMode] = useState(false);
  const [modalCancelData, setCancelData] = useState<IModalData>({
    isOn: false,
    title: '토큰 발행 신청을 취소하시겠습니까?',
    contents: `취소가 완료된 후에는 더 이상 심사가 진행되지 않으며, \n 이후 다시 심사를 진행할 수 없습니다.`,
  });
  const navigate = useNavigate();
  const [modalModifyData, setModalModifyData] = useState<IModalData>({
    isOn: false,
    title: '입력하신 정보로 수정하시겠습니까?',
    contents: `수정 시 변경된 정보로 심사 신청이 접수되며, \n 심사 진행이 이루어지면 정보 수정이 불가능합니다.`,
  });

  const [modalRetryData, setModalRetryData] = useState<IModalData>({
    isOn: false,
    title: '입력하신 정보로 재심사 신청을 하시겠습니까?',
    contents: ` 재심사 요청 시 변경된 정보로 심사가 진행되며, \n 이후 정보 수정이 불가능합니다.`,
  });

  const { stoItem, setStoItem, landFile, isReviewRefused } = useStoContext();

  const {
    getStoHistory: { data: item },
    cancelStoHistory,
    updateStoHistory,
  } = useSto({ stoId: id, type: 'history' });

  const handleModalClose = () => {
    setCancelData({
      ...modalCancelData,
      isOn: false,
    });
    setModalModifyData({
      ...modalModifyData,
      isOn: false,
    });
    setModalRetryData({
      ...modalRetryData,
      isOn: false,
    });
  };

  const handleCancelSto = () => {
    cancelStoHistory.mutate(id as string, {
      onSuccess: () => {
        setEditMode(!editMode);
        handleModalClose();
        setContents?.('신청 정보가 수정되었습니다.');
      },
      onError: (e) => {
        if (e instanceof Error) {
          console.log(e);
          setContents?.(e.message);
        }
      },
    });
  };

  const handleUpdateSto = () => {
    updateStoHistory.mutate(
      {
        type: item?.token.stoTrType as string,
        requestData: {
          requestData: stoItem,
          landFile: landFile as File,
        },
        id: id as string,
      },
      {
        onSuccess: () => {
          setEditMode(!editMode);
          handleModalClose();
          setContents?.('신청 정보가 수정되었습니다.');
        },
      },
    );
    if (isReviewRefused) {
      setContents?.(
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3 style={{ marginBottom: '10px' }}>재심사 신청이 완료되었습니다.</h3>
          <p>심사 결과는 영업일 기준 7일 이내에</p>
          <p>{`[마이페이지 > 토큰 발행 내역]에서 확인 가능합니다.`}</p>
        </div>,
      );
      setTime?.(2400);
    }
  };

  useEffect(() => {
    if (item && setStoItem) {
      const data = { ...item.sto, ...item.token, status: item.token.stoReview?.status || '' };
      setStoItem(data);
    }
  }, [editMode, item]);

  return (
    <main className="deploy-history-item">
      {modalCancelData.isOn && (
        <ConfirmModal modalData={modalCancelData} onClickClose={handleModalClose}>
          <Button variant="outline" className="modal-button" onClick={handleCancelSto}>
            신청 취소
          </Button>
          <Button className="modal-button" onClick={handleModalClose}>
            닫기
          </Button>
        </ConfirmModal>
      )}
      {modalModifyData.isOn && (
        <ConfirmModal modalData={modalModifyData} onClickClose={handleModalClose}>
          <Button className="modal-button" onClick={handleUpdateSto}>
            수정하기
          </Button>
          <Button variant="outline" className="modal-button" onClick={handleModalClose}>
            취소
          </Button>
        </ConfirmModal>
      )}
      {modalRetryData.isOn && (
        <ConfirmModal modalData={modalRetryData} onClickClose={handleModalClose}>
          <Button className="modal-button" variant="outline" onClick={handleModalClose}>
            취소
          </Button>
          <Button className="modal-button" onClick={handleUpdateSto}>
            신청하기
          </Button>
        </ConfirmModal>
      )}
      <div className="top-nav">
        <p className="top-nav__title">
          <button onClick={() => navigate('/my-page/deploy-history')}>토큰 발행 내역</button>
          <span className="bold"> / 내역 상세보기</span>
        </p>
        <div className="top-nav__buttons">
          <span className="timestamp">{`최초 신청일자: ${dayjs(item?.token.createTime).format('YYYY.MM.DD')}`}</span>
          <button className="button">히스토리 {'>'}</button>
        </div>
      </div>
      <section className="info-container">
        <TokenInfo editMode={editMode} volume={item?.token.volume || 0} service={item?.token.service || ''} />
        <StockInfo
          setEditMode={setEditMode}
          editMode={editMode}
          setCancelData={setCancelData}
          modalCancelData={modalCancelData}
          modalModifyData={modalModifyData}
          modalRetryData={modalRetryData}
          setModalModifyData={setModalModifyData}
        />
      </section>
    </main>
  );
}
