import { apiErrorHandler, setApiConfig, setReissueToken } from '@utils/authorization';
import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    setApiConfig(config);
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    if (!response.data.success) {
      apiErrorHandler(response.data);
    }
    if (response.headers.Authorization) {
      setReissueToken(response, api);
    }
    return response;
  },
  (error) => {
    return apiErrorHandler(error.response.data);
  },
);
