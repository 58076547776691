import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactElement,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { LoginUser, User } from '@@types/globalTypes';
import { useNavigate } from 'react-router-dom';
import { signIn as login } from '@apis/users';
import { useQueryClient } from 'react-query';

export interface IAuthContextProvider {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[];
}

interface IAuthContext {
  user: User | null;
  invalid: { isInvalid: boolean; message: string };
  toggleProfile: boolean;
  signIn?: ({ email, password }: LoginUser) => void;
  signOut?: () => void;
  setToggleProfile?: Dispatch<SetStateAction<boolean>>;
  setInvalid?: Dispatch<
    SetStateAction<{
      isInvalid: boolean;
      message: string;
    }>
  >;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  invalid: { isInvalid: false, message: '' },
  toggleProfile: false,
});

export function AuthContextProvider({ children }: IAuthContextProvider) {
  const [user, setUser] = useState<User | null>(JSON.parse(localStorage.getItem('user') as string));
  const [invalid, setInvalid] = useState({ isInvalid: false, message: '계정 정보를 입력해주세요.' });
  const [toggleProfile, setToggleProfile] = useState(false);
  const queryClient = useQueryClient();
  const navigation = useNavigate();

  const signIn = async (loginUser: LoginUser) => {
    try {
      if (loginUser.email && loginUser.password) {
        const response = await login(loginUser);
        if (!response.success) {
          return setInvalid({ isInvalid: true, message: '입력하신 정보와 일치하는 사용자가 없습니다.' });
        }
        if (response.data.role === 'ROLE_ADMIN') {
          navigation('/admin');
        }
        return setUser(response.data);
      }
      return setInvalid({ isInvalid: true, message: '이메일과 비밀번호를 입력해주세요' });
    } catch (e) {
      alert(`에러가 발생하였습니다. ${e}`);
      return setInvalid({ ...invalid, isInvalid: false });
    }
  };

  const signOut = async () => {
    queryClient.clear();
    localStorage.removeItem('user');
    setUser(null);
  };

  useEffect(() => {
    if (user?.role === 'ROLE_ADMIN') {
      navigation('/admin');
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, invalid, signIn, signOut, toggleProfile, setToggleProfile, setInvalid }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
