import tokenManage1 from '@assets/elements/tokenManage1.png';
import tokenManage2 from '@assets/elements/tokenManage2.png';
import { Tab } from '@components/common';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SubscriptionInfo() {
  const [currentTab, setCurrentTab] = useState('진행중');

  return (
    <div className="page_layout">
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '38px', minWidth: '932px' }}>
        <div>
          <Tab
            title="청약정보"
            tabElements={['진행중', '진행예정', '종료']}
            length={2}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>
        <Link to="/subscription-info/detail">
          <img src={tokenManage1} />
        </Link>
        <Link to="/subscription-info/detail">
          <img style={{ marginTop: '24px' }} src={tokenManage2} />
        </Link>
      </div>
    </div>
  );
}
