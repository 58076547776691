import { Icon } from '@components/common';
import { SetStateAction, Dispatch } from 'react';

interface ICheckBoxForm {
  className?: string;
  isChecked: boolean;
  title: string;
  setIsChecked?: Dispatch<SetStateAction<boolean>>;
  onClick?: () => void;
  bold?: boolean;
  bold2?: boolean;
  thin?: boolean;
  thin2?: boolean;
  require?: boolean;
  select?: boolean;
  view?: boolean;
  viewClick?: () => void;
}

const CheckBoxForm = ({
  className,
  isChecked,
  title,
  setIsChecked,
  onClick,
  bold,
  bold2,
  thin,
  thin2,
  require,
  select,
  view,
  viewClick,
}: ICheckBoxForm) => {
  const handleClickCheckButton = () => {
    if (setIsChecked) {
      setIsChecked(!isChecked);
    } else if (onClick) {
      onClick();
    }
  };
  const handleViewClick = () => {
    if (viewClick) {
      viewClick();
    }
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <label className={`checkBoxForm ${className}`} onClick={handleClickCheckButton}>
        {/* <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} /> */}
        {isChecked ? <Icon iconName="checked_blue" /> : <Icon iconName="unchecked_checkbox" />}
        <span
          className={`title ${bold ? 'bold' : ''} ${select || thin ? `thin` : ''} ${thin2 ? `thin2` : ''} ${
            bold2 ? `bold2` : ''
          }`}
        >
          {require ? `[필수] ` : ''}
          {select ? `[선택] ` : ''}
          {title}
        </span>
      </label>
      {view ? (
        <button className="view_button" onClick={handleViewClick}>
          전문보기
        </button>
      ) : null}
    </div>
  );
};

export default CheckBoxForm;
