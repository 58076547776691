import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchApplications, postTokenCreate, postReviewStatus, getStoHubList } from '@apis/admin';
import { IAppList } from '@@types/admin';
import { useToastContext } from '@context/ToastContext';

interface IUseAdmin {
  page?: number;
  stoId?: string;
}

export interface IReviewChangeData {
  status: string;
  comment: string;
  stoInfoId: string;
}

export default function useAdmin({ page }: IUseAdmin) {
  const queryClient = useQueryClient();
  const { setContents } = useToastContext();

  const getApplications = useQuery<IAppList>(['applicationList', page], () => fetchApplications(page), {});

  const tokenCreate = useMutation(({ stoId }: { stoId: string }) => postTokenCreate(stoId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['applicationList', page]);
      setContents?.('발행이 완료되었습니다.');
    },
    onError: () => {
      setContents?.('잘못된 요청입니다 다시 시도해주세요');
    },
  });

  const changeReviewStatus = useMutation(
    ({ status, comment, stoInfoId }: IReviewChangeData) => postReviewStatus({ status, comment, stoInfoId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['applicationList', page]);
        setContents?.('심사 상태가 변경되었습니다.');
      },
      onError: () => {
        setContents?.('잘못된 요청입니다 다시 시도해주세요');
      },
    },
  );

  // const getStoHubLists = useQuery(['stohubList', page], () => getStoHubList(page), {});

  return { getApplications, tokenCreate, changeReviewStatus };
}
