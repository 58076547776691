import { StageLabel } from '@components/MyPage/DeployHistory';
import useSto from '@hooks/useSto';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Icon, Image, Loading, Pagination } from '@components/common';
import { commaSeparator } from '@utils/string';

export default function DeployHistory() {
  const [page, setPage] = useState<number | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paramsPage = parseInt(searchParams.get('page') || '0');

  const {
    getStoHistories: { data, isFetching },
  } = useSto({ page, type: 'histories' });

  const handleStageLabelClick = (e: React.MouseEvent<HTMLLIElement>, id: string) => {
    navigate(`${id}?page=${page}`);
  };

  const handleGoDeploy = () => {
    navigate('/deploy');
  };
  const handlePageChange = (e: { selected: number }) => {
    setPage(e.selected);
    navigate(`?page=${e.selected}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search).get('page');
    if (searchParams) {
      return setPage(parseInt(searchParams));
    }
    setPage(0);
  }, []);

  return (
    <div className="deploy-history">
      {isFetching && <Loading />}
      {data && (
        <>
          <div className="page-header">
            <h1 className="page-header__title"> 토큰 발행 내역</h1>
            <Button
              color="grey"
              className="page-header__button"
              endIcon={<Icon iconName="go_right" />}
              onClick={() => handleGoDeploy()}
            >
              발행 신청 바로가기
            </Button>
          </div>
          <section className="deploy-history__table-wrapper">
            {!data?.stoInfoList?.length ? (
              <p className="empty-list">토큰 발행 내역이 없습니다.</p>
            ) : (
              <ul className="table">
                <li className="list-item header">
                  {tableMenu.map(({ label, type }) => (
                    <p key={type} className={`item ${type}`}>
                      {label}
                    </p>
                  ))}
                </li>
                {data.stoInfoList.map(({ stoReview, stoInfoId, imageUrl, name, symbol, createTime, volume }) => (
                  <li key={stoInfoId} className="list-item" onClick={(e) => handleStageLabelClick(e, stoInfoId)}>
                    <Image imageUrl={imageUrl} className="item image" />
                    <p className="item name">{name}</p>
                    <p className="item symbol">{symbol}</p>
                    <p className="item timestamp">{createTime}</p>
                    <p className="item amount">{commaSeparator(volume)}</p>
                    <div className="item stage">
                      <StageLabel type={stoReview.status} />
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {data?.stoInfoListPageble && data?.stoInfoListPageble.totalPages >= 2 && (
              <Pagination
                handlePageChange={handlePageChange}
                initialPage={paramsPage}
                totalPage={data?.stoInfoListPageble?.totalPages as number}
                currentPage={data?.stoInfoListPageble?.page as number}
              />
            )}
          </section>
        </>
      )}
    </div>
  );
}

const tableMenu = [
  { label: '이미지', type: 'image' },
  { label: '토큰명', type: 'name' },
  { label: '심볼', type: 'symbol' },
  { label: '발행신청일', type: 'timestamp' },
  { label: '발행수량', type: 'amount' },
  { label: '진행상태', type: 'stage' },
];
